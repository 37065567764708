import { useIntl } from "react-intl";

export const useFirstTimeModalSuccessData = () => {
    
    const intl = useIntl();

    const messages = {
        title: intl.formatMessage({ id:"first-time-modal-success-title" }),
        success: intl.formatMessage({ id:"first-time-modal-success-message" }),
        success_new_code: intl.formatMessage({ id:"first-time-modal-new-verificaion-code-message" }),
        button_close: intl.formatMessage({ id:"button-close" }),
        button_verify: intl.formatMessage({ id:"button-verify" }),
        email_not_found: intl.formatMessage({ id:"first-time-modal-email-domain-not-fund" }),
    }

    return { 
        messages,
    };
};