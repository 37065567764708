import { Modal } from 'react-bootstrap';
import { OriginalIcecreamPandito2Icon } from '../../../../images/Icons/icons';
import DropdownLenguagesMenu from '../../DropdownLenguagesMenu';
import FirstTimeModalHomeForm from './FirstTimeModalHomeForm';
import { useFirstTimeModalHomeData } from './useFirstTimeModalHomeData';

const FirstTimeModalHome = ({
        showFirstTimeModalHome,
        setShowFirstTimeModalHome,
        setShowFirstTimeModalSuccess,
        setShowFirstTimeModalNewVerificationCode,
        setUserEamil,
    }) => {
    
    const { firstTimeModalHomeIntlModalInfo } = useFirstTimeModalHomeData();
    return (
        <div>
        <Modal size='xl' show={showFirstTimeModalHome} onHide={() => setShowFirstTimeModalHome(false)}>
            <div  id='modal-one-time-home-image'>
                <div className='modal-one-time-home-background'>
                    <Modal.Header>
                        <Modal.Title className='modal-title-news brand-name-font-style row-box'>
                        <div className='dropdown-menu-modal'>
                            <DropdownLenguagesMenu />
                        </div>
                            <img src={OriginalIcecreamPandito2Icon} alt='icecream-cone-icon'></img>
                            <h1>{firstTimeModalHomeIntlModalInfo.modal_title}</h1>
                        </Modal.Title>
                    </Modal.Header>
                    <div >
                        <Modal.Body className='first-time-modal-home-body'>
                        <div className='p-container'>
                            <p>{firstTimeModalHomeIntlModalInfo.modal_body}</p>
                            <p>{firstTimeModalHomeIntlModalInfo.modal_info}</p>
                        </div>
                            <FirstTimeModalHomeForm 
                                setShowFirstTimeModalHome={setShowFirstTimeModalHome}
                                setShowFirstTimeModalSuccess={setShowFirstTimeModalSuccess}
                                setShowFirstTimeModalNewVerificationCode={setShowFirstTimeModalNewVerificationCode}
                                setUserEamil={setUserEamil}
                            />
                        </Modal.Body>
                    </div>
                </div>
            </div>
        </Modal>
    </div>
    );
}

export default FirstTimeModalHome;