import { BrowserRouter as Router } from 'react-router-dom';
import { IntlManager } from './i18n/InitManager.js';
import { createLenguageManagerSlice } from './store/slices/createLenguageManagerSlice.js';
import MainApp from './components/main_app/MainApp.jsx';

function App() {
  const { locale } = createLenguageManagerSlice();

  return (
    <IntlManager locale={locale}>
      <Router>
        <MainApp />
      </Router>
    </IntlManager>
  );
}

export default App;
