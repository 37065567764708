
import { create } from 'zustand';

const locale = localStorage.getItem('locale') || 'de';

export const createLenguageManagerSlice = create((set, get) => ({

  locale: locale,

  handleLanguage: (lenguage) => set({ locale: lenguage }),

}));
