import { useIntl } from "react-intl";

const useBackendErrorsMessages = () => {

    const intl = useIntl();

    const userErrorCodes = {
        email_not_valid: intl.formatMessage({ id: "email-not-valid" }),
        email_already_in_use: intl.formatMessage({ id: "email-already-in-use" }),
        password_invalid_length: intl.formatMessage({ id: "password-invalid-length" }),
        password_not_valid: intl.formatMessage({ id: "password-not-valid" }),
        email_not_registrated: intl.formatMessage({ id: "email-not-registrated" }),
        server_error_proccesing_password: intl.formatMessage({ id: "server-error-proccesing-password" }),
        server_error_generating_token: intl.formatMessage({ id: "server-error-generating-token" }),
        error_while_creating_user: intl.formatMessage({ id: "error-while-creating-user" }),
        invalid_credentials: intl.formatMessage({ id: "invalid-credentials" }),
        token_has_expired: intl.formatMessage({ id: "token-has-expired" }),
        server_error_checking_credentials: intl.formatMessage({ id: "server-error-checking-credentials" }),
        unknknown_error_while_verifying_token: intl.formatMessage({ id: "unknknown-error-while-verifying-token" }),
        no_more_users_registrations_allowed: intl.formatMessage({ id: "no-more-users-registrations-allowed" }),
        admin_access_only: intl.formatMessage({ id: "admin-access-only" }),
        unknown_error_while_verifying_token: intl.formatMessage({ id: "unknown-error-while-verifying-token" }),
    };
    
    const geolocationErrorCodes = {
        invalid_coordinate_provided: intl.formatMessage({ id: "invalid-coordinate-provided" }),
        server_error_while_updating_coordinates: intl.formatMessage({ id: "server-error-while-updating-coordinates" }),
        error_while_updating_coordinates: intl.formatMessage({ id: "error-while-updating-coordinates" }),
        server_error_while_getting_coordinates: intl.formatMessage({ id: "server-error-while-getting-coordinates" }),
        not_coordinates_funded: intl.formatMessage({ id: "not-coordinates-funded" })
    };
    
    const cateringFormErrorCodes = {
        email_not_valid: intl.formatMessage({ id: "email-not-valid" }),
        name_not_valid: intl.formatMessage({ id: "name-not-valid" }),
        surname_not_valid: intl.formatMessage({ id: "surname-not-valid" }),
        company_not_valid: intl.formatMessage({ id: "company-not-valid" }),
        company_address_not_valid: intl.formatMessage({ id: "company-address-not-valid" }),
        phone_number_not_valid: intl.formatMessage({ id: "phone-number-not-valid" }),
        date_not_valid: intl.formatMessage({ id: "date-not-valid" }),
        time_not_valid: intl.formatMessage({ id: "time-not-valid" }),
        type_of_event_not_valid: intl.formatMessage({ id: "type-of-event-not-valid" }),
        location_not_valid: intl.formatMessage({ id: "location-not-valid" }),
        number_of_people_not_valid: intl.formatMessage({ id: "number-of-people-not-valid" }),
        message_not_valid: intl.formatMessage({ id: "message-not-valid" }),
        server_error_while_sending_email: intl.formatMessage({ id: "server-error-while-sending-email" })
    };

    const newsletterErrorCodes = {
        email_not_valid: intl.formatMessage({ id: "email-not-valid" }),
        name_not_valid: intl.formatMessage({ id: "name-not-valid" }),
        email_already_in_use: intl.formatMessage({ id: "email-already-in-use" }),
        error_while_processing_newsletter_registration: intl.formatMessage({ id: "error-while-processing-newsletter-registration" }),
        user_already_veryficated: intl.formatMessage({ id: "user-already-veryficated" }),
        email_not_registrated: intl.formatMessage({ id: "email-not-registrated" }),
        server_error_while_verifying_user_email: intl.formatMessage({ id: "server-error-while-verifying-user-email" }),
        server_error_while_verifying_user_data: intl.formatMessage({ id: "server-error-while-verifying-user-data" }),
        invalid_credentials: intl.formatMessage({ id: "invalid-credentials" }),
        language_not_valid: intl.formatMessage({ id: "language-not-valid" }),
        server_error_while_getting_newsletter_users: intl.formatMessage({ id: "server-error-while-getting-newsletter-users" }),
        subject_not_valid: intl.formatMessage({ id: "subject-not-valid" }),
        message_not_valid: intl.formatMessage({ id: "message-not-valid" }),
        some_email_delivers_failed: intl.formatMessage({ id: "some-email-delivers-failed" }),
        server_error_while_sending_emails: intl.formatMessage({ id: "server-error-while-sending-emails" }),
        no_verified_users_found_for_selected_language: intl.formatMessage({ id: "no-verified-users-found-for-selected-language" }),
        user_already_unsubscribed: intl.formatMessage({ id: "user-already-unsubscribed" }),
        server_error_while_unsubscribing_user: intl.formatMessage({ id: "server-error-while-unsubscribing-user" }),
        invalid_credentials_please_try_again_later_or_contact_us: intl.formatMessage({ id: "invalid-credentials-please-try-again-later-or-contact-us" }),
        your_token_has_expired_we_have_sent_you_an_email_with_new_credentials: intl.formatMessage({ id: "your-token-has-expired-we-have-sent-you-an-email-with-new-credentials" }),
        invalid_token_we_have_sent_you_an_email_with_new_credentials: intl.formatMessage({ id: "invalid-token-we-have-sent-you-an-email-with-new-credentials" }),
        unknown_error_while_verifying_token_we_have_sent_you_an_email_with_new_credentials: intl.formatMessage({ id: "unknown-error-while-verifying-token-we-have-sent-you-an-email-with-new-credentials" }),
        server_error_while_updating_newsletter_language: intl.formatMessage({ id: "server-error-while-updating-newsletter-language" }),
        the_selected_language_is_the_same_as_the_current_language: intl.formatMessage({ id: "the-selected-language-is-the-same-as-the-current-language" }),
    };

    const genericErrorCodes = {
        generic_error_message: intl.formatMessage({ id: "generic-error-message" }),
    }

    const backendErrors = {
        ...cateringFormErrorCodes,
        ...geolocationErrorCodes,
        ...userErrorCodes,
        ...genericErrorCodes,
        ...newsletterErrorCodes,
    };

    return { backendErrors }

}

export default useBackendErrorsMessages;
