import { useIntl } from "react-intl";

const useNewsletterEmailSenderData = () => {
    
    const intl = useIntl();

    const newsletterData = {
        newsletter_table_header_username: intl.formatMessage({ id: "newsletter-table-header-username" }),
        newsletter_table_header_email: intl.formatMessage({ id: "newsletter-table-header-email" }),
        newsletter_table_header_language: intl.formatMessage({ id: "newsletter-table-header-language" }),
        newsletter_table_header_verifyed: intl.formatMessage({ id: "newsletter-table-header-verifyed" }),
        newsletter_title: intl.formatMessage({ id: "newsletter-title" }),
        newsletter_no_users_to_show: intl.formatMessage({ id: "newsletter-no-users-to-show" }),
        newsletter_show_english_users_button: intl.formatMessage({ id: "newsletter-show-english-users-button" }),
        newsletter_show_spanish_users_button: intl.formatMessage({ id: "newsletter-show-spanish-users-button" }),
        newsletter_show_german_users_button: intl.formatMessage({ id: "newsletter-show-german-users-button" }),
        newsletter_show_all_users_button: intl.formatMessage({ id: "newsletter-show-all-users-button" }),
        newsletter_show_users_component_button: intl.formatMessage({ id: "newsletter-show-users-component-button" }),
        newsletter_hide_users_component_button: intl.formatMessage({ id: "newsletter-hide-users-component-button" }),
        newsletter_get_users_button: intl.formatMessage({ id: "newsletter-get-users-button" }),
        newsletter_show_form_component: intl.formatMessage({ id: "newsletter-show-form-component" }),
        newsletter_hide_form_component: intl.formatMessage({ id: "newsletter-hide-form-component" }),
        newsletter_no_user_registered_yet: intl.formatMessage({ id: "newsletter-no-user-registered-yet" }),
    }

    const newsletterFormData = [
        { id: "to", type:"hidden", label: intl.formatMessage({ id: "newsletter-form-to-label" })},
        { id: "subject", type:"text", label: intl.formatMessage({ id: "newsletter-form-subject-label" }), placeholder: intl.formatMessage({ id: "newsletter-form-subject-placeholder" })},
        { id: "message", type:"hidden", label: intl.formatMessage({ id: "newsletter-form-message-label" })},
    ]

    const newsletterFormDataErrors = {
        newsletter_form_to_error_required: intl.formatMessage({ id: "newsletter-form-to-error-required" }),
        newsletter_form_to_error_invalid: intl.formatMessage({ id: "newsletter-form-to-error-invalid" }),
        newsletter_form_subject_error_required: intl.formatMessage({ id: "newsletter-form-subject-error-required" }),
        newsletter_form_message_error_required: intl.formatMessage({ id: "newsletter-form-message-error-required" }),
    }

    const newsletterFormMessages = {
        newsletter_img_loader_message: intl.formatMessage({ id: "newsletter-form-img-loader-message" }),
        newsletter_sending_email_all_success: intl.formatMessage({ id: "newsletter-form-sending-email-all-success" }),
        newsletter_some_email_deliver_failed: intl.formatMessage({ id: "newsletter-form-some-email-deliver-failed" }),
    }

    const newsletterButtons = {
        newsletter_form_to_spanish: intl.formatMessage({ id: "newsletter-form-to-spanish" }),
        newsletter_form_to_english: intl.formatMessage({ id: "newsletter-form-to-english" }),
        newsletter_form_to_german: intl.formatMessage({ id: "newsletter-form-to-german" }),
        newsletter_form_to_test: intl.formatMessage({ id: "newsletter-form-to-test" }),
        button_send: intl.formatMessage({ id: "button-send" }),
        newsletter_form_show_failed_emails_button: intl.formatMessage({ id: "newsletter-form-show-failed-emails-button" }),
    }

    const initialValues = {
        to: '',
        subject: '',
        message: '',
    }

    return { 
        newsletterData,
        newsletterFormData,
        newsletterFormDataErrors,
        newsletterFormMessages,
        newsletterButtons,
        initialValues
    }
        
}

export default useNewsletterEmailSenderData;
