import { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { createMessageSlice } from '../../../../store/slices/createMessageSlice.js';
import useBackendErrorsMessages from '../../../molecules/messageManager/useBackendErrorsMessages.js';
import { backendErrorMessageProcessor } from '../../../molecules/messageManager/backendErrorMessageProcessor.js';
import postService from '../../../../services/post_services/postService.js';
import BeigeButton from '../../../atoms/buttons/BeigeButton.jsx';
import { useFirstTimeModalHomeData } from './useFirstTimeModalHomeData.js';
import { useFirstTimeModalHomeYupValidations } from './useFirstTimeModalHomeYupValidations.js';
import FirstTimeModalHomeFormDataMap from './FirstTimeModalHomeFormDataMap.jsx';
import { Button } from 'react-bootstrap';

const FirstTimeModalHomeForm = ({
        setShowFirstTimeModalHome,
        setShowFirstTimeModalSuccess,
        setShowFirstTimeModalNewVerificationCode,
        setUserEamil, 
    }) => {
    
        const {
            firstTimeModalHomeIntlFormData,
            firstTimeModalHomeIntlMessages,
            firstTimeModalHomeIntlButtons,
            firstTimeModalHomeInitialValues,
        } = useFirstTimeModalHomeData();
        
        const { registerSchema } = useFirstTimeModalHomeYupValidations();
        const [submittingForm, setSubmittingForm] = useState(false);
        const { addMessage } = createMessageSlice();
        const { backendErrors } = useBackendErrorsMessages();
        const [showButtonEmailInUse, setShowButtonEmailInUse] = useState(false);

        const handleFormSubmit = async (values) => {
            setSubmittingForm(true);
            addMessage({ type: "submitting", content: firstTimeModalHomeIntlMessages.submittingMessage })
            const url = '/newsletter/register';
            const response = await postService(url, values);
            if (response.success) {
                setUserEamil(values.email)
                addMessage({ type: "submitting", content: '' })
                setShowFirstTimeModalHome(false)
                setShowFirstTimeModalSuccess(true)
            } else {
                const errorsTranslated = backendErrorMessageProcessor(backendErrors, response.errors);
                addMessage({ type: "error", content: errorsTranslated })
                response.errors?.forEach((element) => {
                    if (element.msg.includes("EMAIL_ALREADY_IN_USE")) {
                        setShowButtonEmailInUse(true);
                    }
                })
            }
            setSubmittingForm(false);
    }

    const generateNewVerificationCode = async (email) => {
        setSubmittingForm(true);
        addMessage({ type: "submitting", content: firstTimeModalHomeIntlMessages.submittingMessageNewVerificationCode })
        const url = '/newsletter/generate-new-verification-code';
        const response = await postService(url, { email: email });
        if (response.success) {
            setUserEamil(email)
            setShowFirstTimeModalHome(false)
            addMessage({ type: "submitting", content: '' })
            setShowFirstTimeModalNewVerificationCode(true)
        } else {
            const errorsTranslated = backendErrorMessageProcessor(backendErrors, response.errors);
            addMessage({ type: "error", content: errorsTranslated })
        }
        setSubmittingForm(false);
    }

    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        setAnimate(true);
        const timer = setTimeout(() => {
          setAnimate(false);
        }, 1500);
    
        return () => clearTimeout(timer);
      }, [showButtonEmailInUse]);

    return (
        <div>
            <Formik
                initialValues = { firstTimeModalHomeInitialValues }
                validationSchema = { registerSchema }
                onSubmit={handleFormSubmit}
            >
            {({ errors, touched, values }) => (
                <Form className='form'>
                    <FirstTimeModalHomeFormDataMap
                        cateringIntlFormData={firstTimeModalHomeIntlFormData}
                        errors={errors}
                        touched={touched}
                    />
                    {showButtonEmailInUse && (
                        <Button
                            className={`new-verification-code ${animate ? 'verification-animation' : ''}`}
                            disabled={submittingForm}
                            onClick={() => generateNewVerificationCode(values.email)}
                            variant='warning'
                        >
                            {firstTimeModalHomeIntlButtons.buttonEmailInUse}
                        </Button>
                    )}
                    <div className='form-button'>
                        <BeigeButton type="submit" disabled={submittingForm}>
                            {firstTimeModalHomeIntlButtons.buttonSuscribe}
                        </BeigeButton>
                    </div>
                </Form>
            )}
            </Formik>
        </div>
    )
};

export default FirstTimeModalHomeForm;
