const partners = {   
    "partners-main-title": "Bei Pandito mit dabei",
    "partner-1-title": "Das Naschwerk",
    "partner-1-content": "Feine Amaretti, bekommst du bei uns an der Ape von dem kleinen Unternehmen das Naschwerk aus Allschwil, Baselland. Zwei Sorten, ohne Gluten hergestellt und passend zu einem guten Kaffee.",
    "partner-1-link": "https://das-naschwerk.com/",
    "partner-2-title": "La Columbiana",
    "partner-2-content": "Unseren Kaffeebohnen beziehen wir von der Basler Kaffeerösterei La Columbiana. Daraus machen wir verschiedene Kaffeespezialitäten wie zum Beispiel Eiskaffee, Cappuccino oder mit einer Kugel Vanilleglacé Affogato.",
    "partner-2-link": "https://lacolumbiana.ch/",
    "partner-3-title": "Miba",
    "partner-3-content": "Die Produkte für unsere Milchglace beziehen von Miba aus Aesch, Baselland. Dazu gehört Milch, Vollrahm und Joghurt, all das was für ein gutes Milchglace benötigt wird.",
    "partner-3-link": "https://mibabasel.ch/",
    "partner-4-title": "Trocken Rohstoffe",
    "partner-4-content": "Alle unsere trockenen Rohstoffe, welche wir für unsere Glacés brauchen, beziehen wir von der Prodega in Pratteln, Baselland.",
    "partner-4-link": "https://www.transgourmet.ch/de/co",
    "partner-5-title": "Pacovis AG",
    "partner-5-content": "Non-Food Artikel sowie unsere veganen Eiscornets und die verschiedenen Nusspasten bekommen wir von Pacovis aus Stetten, Aargau.",
    "partner-5-link": "https://www.pacovis.ch/stetten",
    "partner-6-title": "Wickelfisch",
    "partner-6-content": "Der Standort unserer Ape am Rhein liegt auf dem Weg zum perfekten Rheineinstieg beim Tinguely Museum in Basel. Die wasserdichte Schwimmtasche ist somit ein Muss und kannst Du ganz einfach bei uns an der Ape kaufen.",
    "partner-6-link": "https://www.wickelfisch.ch/",    
    "partner-7-title": "Westfeld",
    "partner-7-content": "Vom Spitalgeländer zum Quartierzentrum. Das Westfeld ist ein lebendiges Stück Stadt, wo auch unsere Gelateria seinen Platz gefunden hat.",
    "partner-7-link": "https://www.westfeld-basel.ch/",
    "partner-8-title": "Tigel",
    "partner-8-content": "Aus dem Tessin, von Tigel, ist unter anderem die Vitrine in unserer Gelateria oder auch unser Schockfroster in der Produktion.",
    "partner-8-link": "https://ti-gel.ch/",
    "partner-9-title": "Espura",
    "partner-9-content": "Schöne Handwerkskunst aus Argentinien & Lateinamerika findet sich in unserer Gelateria wieder, wie zum Beispiel die Deckenlampen. Die schöne Boutique mit vielen Einzelstücken hat ihren Sitz in Flumenthal, Solothurn.",
    "partner-9-link": "https://espura.ch/",
}

export default partners;