import './template.css';
import { SaraLuciColorBackShort, LuciPhoto, SaraPhoto } from '../../images/AboutUs/AboutUs';
import { Row, Col } from 'react-grid-system';
import { FormattedMessage } from 'react-intl';

const AboutUs = () => {

    return (
        <div className='template'>
            <div className='top-template-image-container'>
            <img src={SaraLuciColorBackShort} className='top-template-image' alt='pandito'></img>
            <h1 className='custom-orange-h1'>
                <FormattedMessage id="sliderItems-2-title" />
            </h1>
            </div>
            <div className='template-box'>
                <p className='custom-black-p'>
                    <FormattedMessage id="aboutUs-1" />
                    <br></br>
                    <FormattedMessage id="aboutUs-2" />
                    <br></br>
                    <FormattedMessage id="aboutUs-3" />
                    <br></br>
                    <FormattedMessage id="aboutUs-4" />
                    <br></br>
                    <FormattedMessage id="aboutUs-5" />
                    <br></br>
                    <FormattedMessage id="aboutUs-6" />
                    <span style={{fontWeight: '900'}}>
                        <FormattedMessage id="aboutUs-7" />
                    </span>
                </p>
                <Row className='description-box-row'>
                    <Col className='description-box' md={12} xl={5}>
                        <img src={SaraPhoto} className='sara-luci-photos' alt='pandito'></img>
                        <p className='custom-black-p'>
                            <FormattedMessage id="aboutUs-Sara-1" />
                            <br></br>
                            <br></br>
                            <FormattedMessage id="aboutUs-Sara-2" />
                            <br></br>
                            <br></br>
                            <FormattedMessage id="aboutUs-Sara-3" />
                        </p>
                    </Col>
                    <Col className='description-box' md={12} xl={5}>
                    <img src={LuciPhoto} className='sara-luci-photos' alt='pandito'></img>
                        <p className='custom-black-p'>
                            <FormattedMessage id="aboutUs-Luci-1" />
                            <br></br>
                            <br></br>
                            <FormattedMessage id="aboutUs-Luci-2" />
                            <br></br>
                            <br></br>
                            <FormattedMessage id="aboutUs-Luci-3" />
                        </p>
                    </Col>
                </Row>
            </div>
            <div className='bottom-spacer'></div>
        </div>
    );
}

export default AboutUs;
