import { ErrorMessage, Field } from "formik";
import Label from "../../../components/atoms/formParts/Label";

const UpdateLanguageFormDataMap = ({ updateLanguageIntlFormData, errors, touched }) => {
    return (
        <div className='form-columns'>
            {updateLanguageIntlFormData.map((data, index) => (
                <div className='form-group' key={index}>
                    <Label>{data.label}</Label>
                        <div>
                        <Field as="select" className='input' id={data.id} name={data.id}>
                            {data.options.map((option, optionIndex) => (
                                <option key={optionIndex} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </Field>
                        <p style={{color:'black', margin:'10px'}}>{data.info}</p>
                        </div>
                    {errors[data.id] && touched[data.id] && (
                        <ErrorMessage className='message error' component="div" name={data.id} />
                    )}
                </div>
            ))}
        </div>
    );
}

export default UpdateLanguageFormDataMap;