import React from 'react';
import PropTypes from 'prop-types';

const WhiteButton = (props) => {
    return (
        <div>
            <button className='button white-button' type={props.type}>{props.children}</button>
        </div>
    );
}

WhiteButton.propTypes = {
    type: PropTypes.node.isRequired,
}

export default WhiteButton;