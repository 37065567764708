const ourProducts = {   
    "OurProducts-1": "Glace Glace Glace",
    "OurProducts-2": "- Diverse Sorten hausgemachtes Eis",
    "OurProducts-3": "Kaffeespezialitäten",
    "OurProducts-4": "Diverse Kaltgetränke",
    "OurProducts-5": "Süssgebäck",
    "OurProducts-6": "Wickelfische in verschiedenen Farben & Grössen",
    "OurProducts-7": "Unsere Glacé lebt davon, dass es mal klassisch, mal kreativ ist aber immer lecker, abwechslungsreich und ein Muss im Sommer. Und nicht zu vergessen: Für jeden Geschmack ist bei Pandito etwas dabei! Wir bieten nämlich die unterschiedlichsten Sorten an: Milchglace, vegane Glacé und erfrischende Sorbets. Am Liebsten verfeinern wir unsere Glace mit Zutaten aus unserer Heimat Argentinien. Dazu gehört natürlich die leckere Milchkaramellcreme Dulce de Leche, der Mate-Tee sowie das argentinische Gebäck Alfajor.",
}

export default ourProducts;