import React from 'react';
import { useUpdateLanguageFormData } from './useUpdateLanguageFormData';
import UpdateLanguageForm from './UpdateLanguageForm';

const UpdateLanguage = () => {

    const { updateLanguageIntlInfo } = useUpdateLanguageFormData();
    
    return (
            <div className='template-box update'>
            <div className='update-language-template'>
                <h2>{updateLanguageIntlInfo.change_language_title}</h2>
                <UpdateLanguageForm />
            </div>
            </div>
    );
}

export default UpdateLanguage;
