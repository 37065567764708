import { useState } from 'react';
import Label from '../../../atoms/formParts/Label';
import { ErrorMessage, Field } from 'formik';
import ImageUploader from './ImageUploader';
import { renderLanguageButtons } from './renderLanguageButtons';
import useNewsletterEmailSenderData from './useNewsletterEmailSenderData';

const NewsletterMapFormData = ({ errors, touched, setFieldValue }) => {

    const {         
        newsletterFormData,
        newsletterFormMessages,
        newsletterButtons,
    } = useNewsletterEmailSenderData();

    const languages = ['spanish', 'english', 'german', 'test'];
    const [languageSelected, setLanguageSelected] = useState('');

    return (
        <div className='form-columns'>
        {newsletterFormData.map((data, index) => (
        <div className='form-group' key={index}>
            <Label>{data.label}</Label>
                <Field
                className='input'
                id={data.id}
                name={data.id}
                autoComplete={data.label}
                placeholder={data.placeholder ? data.placeholder : ''}
                type={data.type}
            />

            {data.id === 'message' ? (
                <ImageUploader 
                    newsletterFormMessages={newsletterFormMessages}
                    setFieldValue={setFieldValue}
                />
            ) : data.id === 'to' ? (
                <div className='language-buttons'>
                    {languages.map((language) =>
                        renderLanguageButtons(language, setLanguageSelected, languageSelected, setFieldValue, newsletterButtons)
                    )}
                </div>
            ) : (
                null
            )}

            {errors[data.id] && touched[data.id] && (
            <ErrorMessage className='message error' component="div" name={data.id} />
            )}

        </div>
        ))}
    </div>
    );
}

export default NewsletterMapFormData;
