const headerNavbar = {
  "headerNavBar-navLink1": "Über uns",
  "headerNavBar-navLink2": "Unser Angebot",
  "headerNavBar-navLink3": "Catering",
  "headerNavBar-navLink4": "Standorte",
  "headerNavBar-navLink5": "Online-shop",
  "headerNavBar-navLink6": "Partner",
  "headerNavBar-navLink7": "Admin",
};

export default headerNavbar;
