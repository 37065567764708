const geolocation = {
    "geolocation-token-error": "Invalid credentials. Please login.",
    "geolocation-not-suported-error": "Geolocation is not supported by your browser",
    "geolocation-permission-denied-error": "User denied the request for Geolocation",
    "geolocation-position-unavailable-error": "Location information is unavailable",
    "geolocation-timeout-error": "The request to get user location timed out",
    "geolocation-default-error": "An unknown error occurred",
    "geolocation-location-updated-success": "Location updated successfully",
    "geolocation-sharing-location-message-1": "You ",
    "geolocation-sharing-location-message-2": "currently sharing your location",
    "geolocation-not-sharing-location-message-span": "ARE NOT ",
    "geolocation-sharing-location-message-span": "ARE ",
    "geolocation-not-sharing-location-message-button": "Click the button below to share it",
    "geolocation-sharing-location-message-button": "Click the button below to stop sharing it",
    "geolocation-button-share-location": "Share current location",
    "geolocation-button-stop-sharing-location": "Stop sharing location",
    "geolocation-button-see-map": "See map",
    "geolocation-button-reload-page": "Reload page",
}

export default geolocation;