const registerForm = {   
    "register-form-username-label": "Username",
    "register-form-username-placeholder": "Your username here...",
    "register-form-username-error-required": "Username is required",
    "register-form-username-error-min": "Username must be at least 2 characters long",
    "register-form-username-error-max": "Username must be at most 50 characters long",
    "register-form-email-label": "Email Address",
    "register-form-email-placeholder": "Your email address here...",
    "register-form-email-error-invalid": "Invalid email address",
    "register-form-email-error-required": "Email is required",
    "register-form-password-label": "Password",
    "register-form-password-placeholder": "Your password here...",
    "register-form-password-error-required": "Password is required",
    "register-form-password-error-invalid": "Password must include at least one uppercase letter, one lowercase letter, one number, and one special character",
    "register-form-password-error-min": "Password must be at least 8 characters long",
    "register-form-password-error-max": "Password must be at most 50 characters long",
    "register-form-password-confirmation-label": "Confirm password",
    "register-form-password-confirmation-placeholder": "Confirm your password here...",
    "register-form-password-confirmation-error-required": "Confirm password is required",
    "register-form-password-confirmation-error-match": "Passwords must match",
    "register-form-sending-form-message": "Creating user, please wait",
    "register-form-success-message": "User created successfully",
}
    
export default registerForm;