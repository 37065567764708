import buttons from "../buttons/buttons.js";
import footerNavBar from "../footers/footerNavBar.js";
import registerForm from "../forms/RegisterForm.js";
import cateringForm from "../forms/cateringForm.js";
import loginForm from "../forms/loginForm.js";
import headerNavBar from "../headers/headerNavBar.js"
import firstTimeModalHome from "../modals/firstTimeHome.js";
import onlineShopping from "../modals/onlineShop.js";
import aboutUs from "../templates/aboutUs.js";
import catering from "../templates/catering.js";
import ourProducts from "../templates/ourProducts.js";
import partners from "../templates/partners.js";
import sliderItems from "../templates/sliderItems.js";
import userErrorCodes from "../backendErrors/userErrorCodes.js";
import geolocationErrorCodes from "../backendErrors/geolocationsErrorCodes.js";
import cateringFormErrorCodes from "../backendErrors/cateringFormErrorCodes.js";
import genericErrorCodes from "../backendErrors/genericErrorCodes.js";
import emailErrorCodes from "../backendErrors/emailErrorCodes.js";
import geolocation from "../templates/geolocation.js";
import firstTimeModalHomeForm from "../forms/firtsTimeModalHomeForm.js";
import newsletterErrorCodes from "../backendErrors/newsletterErrorCodes.js";
import firstTimeModalSuccess from "../modals/firstTimeModalSuccess.js";
import firstTimeModalNewVerificationCode from "../modals/firstTimeModalNewVerificationCode.js";
import admin from "../templates/admin.js";
import newsletter from "../templates/newsletter.js";
import newsletterForm from "../forms/newsleterForm.js";
import home from "../templates/home.js";
import updateLanguage from "../templates/update-language.js";

const esTranslator = {
    ...headerNavBar,
    ...firstTimeModalHome,
    ...sliderItems,
    ...footerNavBar,
    ...aboutUs,
    ...ourProducts,
    ...catering,
    ...onlineShopping,
    ...partners,
    ...cateringForm,
    ...buttons,
    ...loginForm,
    ...registerForm,
    ...userErrorCodes,
    ...geolocationErrorCodes,
    ...cateringFormErrorCodes,
    ...genericErrorCodes,
    ...emailErrorCodes,
    ...geolocation,
    ...firstTimeModalHomeForm,
    ...newsletterErrorCodes,
    ...firstTimeModalSuccess,
    ...firstTimeModalNewVerificationCode,
    ...admin,
    ...newsletter,
    ...newsletterForm,
    ...home,
    ...updateLanguage
}

export default esTranslator;