const userErrorCodes = {
    "email-not-valid": "ungültige E-Mail-Adresse",
    "email-already-in-use": "E-Mail-Adresse bereits in Verwendung",
    "password-invalid-length": "ungültige Passwortlänge",
    "password-not-valid": "ungültiges Passwort",
    "email-not-registrated": "E-Mail-Adresse nicht registriert",
    "server-error-proccesing-password": "Fehler bei der Verarbeitung des Passworts auf dem Server",
    "server-error-generating-token": "Fehler bei der Generierung des Tokens auf dem Server",
    "error-while-creating-user": "Fehler bei der Erstellung des Benutzers",
    "invalid-credentials": "ungültige Anmeldeinformationen",
    "token-has-expired": "das Token ist abgelaufen",
    "server-error-checking-credentials": "Fehler beim Überprüfen der Anmeldeinformationen auf dem Server",
    "unknknown-error-while-verifying-token": "unbekannter Fehler bei der Überprüfung des Tokens",
    "no-more-users-registrations-allowed": "keine weiteren Benutzerregistrierungen erlaubt",
    "admin-access-only": "Nur Admin-Zugang",
    "unknown-error-while-verifying-token": "Unbekannter Fehler bei der Überprüfung des Tokens", 
};

export default userErrorCodes;
