import postService from "../../../../services/post_services/postService";

// Check user credentials
export const handleAdminPermissions = async (
        backendErrorMessageProcessor,
        setLoading,
        addMessage,
        navigate,
        PATHS,
        backendErrors,
        geolocationErrors
    ) => {

    setLoading(true)
    const token = localStorage.getItem("token");

    if (!token) {
        addMessage({ type: "error", content: geolocationErrors.geolocation_token_error });
        setLoading(false)
        navigate(PATHS.ADMIN.GEOLOCATION_LOGIN)
        return
    }

    const url = '/auth/check-admin';
    const response = await postService(url, { token });
    setLoading(false)

    if (!response.success) {
        localStorage.removeItem("token")
        const errorsTranslated = backendErrorMessageProcessor(backendErrors, response.errors);
        addMessage({ type: "error", content: errorsTranslated })
        navigate(PATHS.ADMIN.GEOLOCATION_LOGIN)
        return
    }
}