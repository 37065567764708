const firstTimeModalHomeForm = {
    "first-time-modal-home-form-name-label": "Name",
    "first-time-modal-home-form-name-placeholder": "Dein Name hier...",
    "first-time-modal-home-form-name-error-min": "Der Name muss mindestens 2 Zeichen lang sein",
    "first-time-modal-home-form-name-error-required": "Name ist erforderlich",
    "first-time-modal-home-form-email-label": "E-Mail-Adresse",
    "first-time-modal-home-form-email-placeholder": "Deine E-Mail-Adresse hier...",
    "first-time-modal-home-form-email-error-invalid": "Ungültige E-Mail-Adresse",
    "first-time-modal-home-form-email-error-required": "E-Mail-Adresse ist erforderlich",
    "first-time-modal-home-form-language-label": "Sprache",
    "first-time-modal-home-form-language-info": "Wählen Sie die Sprache, in der Sie die Nachrichten erhalten möchten",
    "first-time-modal-home-form-language-select": "Wählen Sie...",
    "first-time-modal-home-form-language-english": "Englisch",
    "first-time-modal-home-form-language-spanish": "Spanisch",
    "first-time-modal-home-form-language-german": "Deutsch",
    "first-time-modal-home-form-language-required": "Sprache ist erforderlich",
    "first-time-modal-home-sending-form-message": "Anmeldung läuft, bitte warten...",
    "first-time-modal-home-sending-form-message-new-verification-code": "Neuen Verifizierungscode generieren, bitte warten",
}

export default firstTimeModalHomeForm;