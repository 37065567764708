import { useEffect, useState } from 'react';
import PrimaryButton from '../../../../atoms/buttons/PrimaryButton.jsx';
import { Formik, Form } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useLoginFormData } from './useLoginFormData.js';
import { useLoginYupValidations } from './useLoginYupValidations.js';
import { LoginMapFormData } from './LoginMapFormData.jsx';
import { useRegisterFormData } from '../geolocation-register/useRegisterFormData.js';
import { createMessageSlice } from '../../../../../store/slices/createMessageSlice.js'
import PATHS from '../../../../../store/models/routes.js';
import useBackendErrorsMessages from '../../../../molecules/messageManager/useBackendErrorsMessages.js';
import { backendErrorMessageProcessor } from '../../../../molecules/messageManager/backendErrorMessageProcessor.js';
import postService from '../../../../../services/post_services/postService.js';

const LoginForm = () => {

    const { loginInitialValues, loginIntlFormData, loginIntlButtons, loginFormMessages } = useLoginFormData();
    const { loginRegisterSchema } = useLoginYupValidations();
    const { registerIntlButtons } = useRegisterFormData();
    const { addMessage } = createMessageSlice();
    const { backendErrors } = useBackendErrorsMessages();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [submittingForm, setSubmittingForm] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem("token")
        if (token) {
            navigate(PATHS.ADMIN.ROOT)
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleFormSubmit = async (values) => {
        setSubmittingForm(true)
        addMessage({ type: "submitting", content: loginFormMessages.submittingMessage })
        const url = '/auth/login-user';
        const response = await postService(url, values);
        if (response.success) {
            const { token, role } = response.data
            localStorage.setItem("token", token);
            localStorage.setItem("role", role);
            addMessage({ type: "success", content: loginFormMessages.successMessage })
            navigate(PATHS.ADMIN.ROOT);    
        } else {
            const errorsTranslated = backendErrorMessageProcessor(backendErrors, response.errors);
            addMessage({ type: "error", content: errorsTranslated })
        }
        setSubmittingForm(false)
    }

return (
    <div className='form-box'>
        <Formik initialValues = { loginInitialValues } validationSchema = { loginRegisterSchema } onSubmit={handleFormSubmit}>
        {({ errors, touched }) => (
        <Form className='form'>
            <LoginMapFormData loginIntlFormData={loginIntlFormData} errors={errors} touched={touched} showPassword={showPassword} setShowPassword={setShowPassword} />
            <PrimaryButton type="submit" disabled={submittingForm} id='largePrimaryButtonForLogin'>
                {loginIntlButtons.buttonSend}
            </PrimaryButton>
            <a style={{marginTop: '20px', textDecoration: 'none' }} href={PATHS.GEOLOCATION_REGISTER}>
                {registerIntlButtons.buttonSend}
            </a>
        </Form>
        )}
        </Formik>
    </div>
    );
}

export default LoginForm;