const cateringFormErrorCodes = {
    "email-not-valid": "correo electrónico no válido",
    "name-not-valid": "nombre no válido",
    "surname-not-valid": "apellido no válido",
    "company-not-valid": "empresa no válida",
    "company-address-not-valid": "dirección de la empresa no válida",
    "phone-number-not-valid": "número de teléfono no válido",
    "date-not-valid": "fecha no válida",
    "time-not-valid": "hora no válida",
    "type-of-event-not-valid": "tipo de evento no válido",
    "location-not-valid": "ubicación no válida",
    "number-of-people-not-valid": "número de personas no válido",
    "message-not-valid": "mensaje no válido",
};

export default cateringFormErrorCodes;
