const getCurrentLocation = () => {

    if (!navigator.geolocation) {
        return ({ success: false, error: "geolocation-not-suported-error" });
    }

    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const location = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };
                return resolve({ success: true, location: location });
            },
            (error) => {
                let errorMessage;
                switch (error.code) {
                    case error.PERMISSION_DENIED:
                        errorMessage = "geolocation_permission_denied_error";
                        break;
                    case error.POSITION_UNAVAILABLE:
                        errorMessage = "geolocation_position_unavailable_error";
                        break;
                    case error.TIMEOUT:
                        errorMessage = "geolocation_timeout_error";
                        break;
                    default:
                        errorMessage = "geolocation_default_error";
                        break;
                }
                return resolve({ success: false, error: errorMessage });
            }
        );
    });
};

export default getCurrentLocation;
