import { useEffect, useState } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import Home from '../template/home/Home.jsx';
import AboutUs from '../template/AboutUs';
import OurProducts from '../template/OurProducts';
import Catering from '../template/catering/Catering.jsx';
import FooterNavBar from '../molecules/FooterNavBar.jsx';
import Geolocation from '../template/admin/currentLocation/geolocation/Geolocation.jsx';
import RegisterForm from '../template/admin/currentLocation/geolocation-register/RegisterForm.jsx';
import LoginForm from '../template/admin/currentLocation/geolocation-login/LoginForm.jsx';
import Partners from '../template/Partners.jsx';
import PATHS from '../../store/models/routes.js';
import getLocationFromBackend from '../template/admin/currentLocation/geolocation/getLocationFromBackend.js';
import { createPanditoLocationSlice } from '../../store/slices/createPanditoLocationSlice.js';
import HeaderNavBar from '../molecules/HeaderNavBar.jsx';
import AdminRoot from '../template/admin/admin-root/AdminRoot.jsx';
import { useGeolocationData } from '../template/admin/currentLocation/geolocation/useGeolocationData.js';
import { handleAdminPermissions } from '../template/admin/admin-root/handleAdminPermissions.js';
import { backendErrorMessageProcessor } from '../molecules/messageManager/backendErrorMessageProcessor.js';
import { createMessageSlice } from '../../store/slices/createMessageSlice.js';
import useBackendErrorsMessages from '../molecules/messageManager/useBackendErrorsMessages.js';
import isValidPath from './isValidPath.js';
import Newsletter from '../template/admin/newsletter/Newsletter.jsx';
import Loading from '../molecules/Loading.jsx';
import MessageManager from '../molecules/messageManager/MessageManager.jsx';
import UpdateLanguage from '../template/update-language/UpdateLanguage.jsx';

function MainApp() {

  const location = useLocation();
  const { setPanditoLocationNow } = createPanditoLocationSlice();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { addMessage } = createMessageSlice();
  const { backendErrors } = useBackendErrorsMessages();
  const { geolocationErrors } = useGeolocationData();

  let currentPath = location.pathname;
  const adminPaths = Object.values(PATHS.ADMIN);
  const isAdminPath = adminPaths.some(path => currentPath.includes(path));

  const getPanditoLocation = async () => {
    await getLocationFromBackend(setPanditoLocationNow);
  };

  useEffect(() => {

    // Css effect when mounting
    const timer2 = setTimeout(() => {
      const loadingOverlay = document.getElementById('loading-overlay-div');
      if (loadingOverlay) {
        loadingOverlay.remove();
      }
    }, 1000);

    // FetchLocationData interval each 5 min
    const intervalId = setInterval(() => {
      getPanditoLocation();
    }, 300000);

    // Initial FetchLocationData
    getPanditoLocation();

    if (currentPath === PATHS.HOME) {
        const hash = window.location.hash;
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }
    
    if (isAdminPath) {
      handleAdminPermissions(backendErrorMessageProcessor, setLoading, addMessage, navigate, PATHS, backendErrors, geolocationErrors);
    }
    
  // Redirect to home view if path not exist
  if (!isValidPath(currentPath, PATHS)) {
    window.location.href = PATHS.HOME;
  }

    return () => {
      clearTimeout(timer2);
      clearInterval(intervalId);
    };
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App">
      <div id="loading-overlay-div" className="loading-overlay">
        <div className="background-animation"></div>
      </div>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <HeaderNavBar currentPath={currentPath} />
      <MessageManager />
      {loading ? (
        <Loading message="admin-loading" />
      ) : (
          <Routes>
            <Route path={PATHS.HOME} element={<Home />} />
            <Route path={PATHS.ABOUT_US} element={<AboutUs />} />
            <Route path={PATHS.OUR_PRODUCTS} element={<OurProducts />} />
            <Route path={PATHS.CATERING} element={<Catering />} />
            <Route path={PATHS.OUR_PARTNERS} element={<Partners />} />
            <Route path={PATHS.UPDATE_LANGUAGE} element={<UpdateLanguage />} />
            <Route path={PATHS.ADMIN.ROOT} element={<AdminRoot />} />
            <Route path={PATHS.ADMIN.GEOLOCATION} element={<Geolocation />} />
            <Route path={PATHS.ADMIN.GEOLOCATION_LOGIN} element={<LoginForm />} />
            <Route path={PATHS.ADMIN.GEOLOCATION_REGISTER} element={<RegisterForm />} />
            <Route path={PATHS.ADMIN.NEWSLETTER} element={<Newsletter />} />
          </Routes>
      )}
      {/* If any admin PATHS ----> Footer will not render */}
      {!isAdminPath && <FooterNavBar />}
    </div>
  );
}

export default MainApp;