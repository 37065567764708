const footerNavBar = {
  "footerNavBar-1": "© Pandito GmbH CH-270.4.008.394-2",
  "footerNavBar-2": "+41 77 409 1740 panditoicecream@gmail.com",
  "footerNavBar-3": "Horario de apertura de Schaffhauserreinweg",
  "footerNavBar-3-tooltip": "Jueves a domingo de 14:00 a 19:30 hs",
  "footerNavBar-4": "Horario de apertura de Im Westfeld",
  "footerNavBar-4-tooltip": "Martes y miercoles de 14:00 a 19:30 hs",
  "footerNavBar-5": "Aviso legal / ",
  "footerNavBar-6": "Política de privacidad",
  "footerNavBar-7": "Diseñado por Fede",
}

export default footerNavBar;