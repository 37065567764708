const newsletterForm = {   
    "newsletter-form-to-label": "Para",
    "newsletter-form-to-spanish": "Usuarios en español",
    "newsletter-form-to-english": "Usuarios en ingles",
    "newsletter-form-to-german": "Usuarios en aleman",
    "newsletter-form-to-test": "Usuario prueba",
    "newsletter-form-to-error-required": "Elige a que usuarios quieres enviar el email",
    "newsletter-form-to-error-invalid": "Usuario/s elegidos no validos",
    "newsletter-form-subject-label": "Asunto",
    "newsletter-form-subject-placeholder": "Escribe el asunto aqui...",
    "newsletter-form-subject-error-required": "Escribe un asunto",
    "newsletter-form-message-label": "Mensaje",
    "newsletter-form-message-error-required": "Debes subir una imagen",
    "newsletter-form-sending-form-message": "Enviando emails. Por favor espere... Esto podria tomar unos minutos",
    "newsletter-form-img-loader-message": "Arrastra la imagen aquí o haz clic para seleccionar",
    "newsletter-form-sending-email-all-success": "Todos los emails han sido enviados correctamente",
    "newsletter-form-some-email-deliver-failed": "Algunos emails han fallado en su envio",
    "newsletter-form-show-failed-emails-button": "Mostrar emails fallidos",
}
    
export default newsletterForm;