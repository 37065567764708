const firstTimeModalHomeForm = {
    "first-time-modal-home-form-name-label": "Nombre",
    "first-time-modal-home-form-name-placeholder": "Tu nombre aquí...",
    "first-time-modal-home-form-name-error-min": "El nombre debe tener al menos 2 caracteres",
    "first-time-modal-home-form-name-error-required": "El nombre es obligatorio",
    "first-time-modal-home-form-email-label": "Dirección de correo electrónico",
    "first-time-modal-home-form-email-placeholder": "Tu dirección de correo electrónico aquí...",
    "first-time-modal-home-form-email-error-invalid": "Correo electrónico inválido",
    "first-time-modal-home-form-email-error-required": "El correo electrónico es obligatorio",
    "first-time-modal-home-form-language-label": "Idioma",
    "first-time-modal-home-form-language-info": "Elige el idioma en el que quieres recibir las noticias",
    "first-time-modal-home-form-language-select": "Seleccione...",
    "first-time-modal-home-form-language-english": "Inglés",
    "first-time-modal-home-form-language-spanish": "Español",
    "first-time-modal-home-form-language-german": "Alemán",
    "first-time-modal-home-form-language-required": "El idioma es obligatorio",
    "first-time-modal-home-sending-form-message": "Suscribiendote, por favor espera",
    "first-time-modal-home-sending-form-message-new-verification-code": "Generando el nuevo codigo de verificacion, por favor espera",
}
 
 export default firstTimeModalHomeForm;