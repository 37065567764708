const geolocation = {
    "geolocation-token-error": "Credenciales inválidas. Por favor, inicie sesión.",
    "geolocation-not-suported-error": "La geolocalización no es compatible con su navegador",
    "geolocation-permission-denied-error": "El usuario negó la solicitud de geolocalización",
    "geolocation-position-unavailable-error": "La información de ubicación no está disponible",
    "geolocation-timeout-error": "La solicitud para obtener la ubicación del usuario se agotó",
    "geolocation-default-error": "Ocurrió un error desconocido",
    "geolocation-location-updated-success": "Ubicación actualizada con éxito",
    "geolocation-sharing-location-message-1": "Usted ",
    "geolocation-sharing-location-message-2": "compartiendo su ubicación actualmente.",
    "geolocation-not-sharing-location-message-span": "NO ESTÁ ",
    "geolocation-sharing-location-message-span": "ESTÁ ",
    "geolocation-not-sharing-location-message-button": "Haga clic en el botón de abajo para compartirla",
    "geolocation-sharing-location-message-button": "Haga clic en el botón de abajo para dejar de compartirla",
    "geolocation-button-share-location": "Compartir ubicación actual",
    "geolocation-button-stop-sharing-location": "Dejar de compartir ubicación",
    "geolocation-button-see-map": "Ver mapa",
    "geolocation-button-reload-page": "Recargar página",
}

export default geolocation;