import { useIntl } from "react-intl";

const useHomeData = () => {

    const intl = useIntl();

    const successMessages = {
        newsletter_unsubscribed_successfully: intl.formatMessage({ id: "newsletter-unsubscribed-successfully" }),
        newsletter_verification_successfully: intl.formatMessage({ id:"newsletter-verification-successfully" }),
    }

    return { successMessages }
};

export default useHomeData;
