const ourProducts = {   
    "OurProducts-1": "Helado, Helado, Helado",
    "OurProducts-2": "- Variedades diversas de helado casero",
    "OurProducts-3": "Especialidades de café",
    "OurProducts-4": "Bebidas frías diversas",
    "OurProducts-5": "Repostería dulce",
    "OurProducts-6": "Wickelfische en varios colores y tamaños",
    "OurProducts-7": "Nuestro helado vive de ser a veces clásico, a veces creativo, pero siempre delicioso, variado y un imprescindible en verano. Y no hay que olvidar: ¡En Pandito hay algo para todos los gustos! Ofrecemos una gran variedad de sabores: helado de leche, helado vegano y sorbetes refrescantes. Nos encanta refinar nuestros helados con ingredientes de nuestra tierra natal, Argentina. Esto incluye, por supuesto, la deliciosa crema de dulce de leche, el mate y las galletas argentinas Alfajor.",
}
  
export default ourProducts;