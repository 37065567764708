import * as Yup from 'yup';
import { useRegisterFormData } from './useRegisterFormData.js';

export const useRegisterYupValidations = () => {

    const { registerIntlErrors } = useRegisterFormData();

    const registerRegisterSchema = Yup.object().shape(
        {
        username: Yup.string()
            .required(registerIntlErrors.username_required)
            .min(2, registerIntlErrors.username_min)
            .max(50, registerIntlErrors.username_max),
        email: Yup.string()
            .email(registerIntlErrors.email_invalid)
            .required(registerIntlErrors.email_required),
        password: Yup.string()
            .required(registerIntlErrors.password_required)
            .min(8, registerIntlErrors.password_min)
            .max(50, registerIntlErrors.password_max)
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/, registerIntlErrors.password_invalid),
        confirmationPassword: Yup.string()
        .required(registerIntlErrors.confirmation_password_required)
        .oneOf([Yup.ref('password'), null], registerIntlErrors.confirmation_password_match),
        }
    )

    return { registerRegisterSchema };
}