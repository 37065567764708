const catering = {   
    "Catering-1": "¿Sigues buscando un complemento dulce para tu oferta de postres? ¡No hay problema! Llegamos con nuestra Ape a tu boda, tu fiesta de cumpleaños o tu evento de equipo.",
    "Catering-2": "En el equipaje, seis variedades diferentes de helado, una máquina de café para diversas especialidades de café y deliciosos Amaretti de \"das Naschwerk\" de Allschwil.",
    "Catering-3": "Completa el formulario de contacto que aparece a continuación para que podamos enviarte una oferta adecuada para tu evento de manera oportuna.",
    "Catering-4": "Ten en cuenta que no podemos viajar largas distancias con nuestra Ape, por lo que principalmente estamos disponibles en la región de Basilea.",
    "Catering-5": "¿Quieres que estemos contigo? ¡Entonces esperamos tu ",
    "Catering-6": "solicitud!",
    "Catering-show-form": "Enviar solicitud! ",
}

export default catering;