const buttons = {   
    "button-send": "Senden",
    "button-cancel": "Abbrechen",
    "button-login": "Anmelden",
    "button-register": "Registrieren",
    "button-subscribe": "Abonnieren",
    "button-close": "Schließen",
    "button-verify": "Öffnen Sie Ihre E-Mails",
    "button-email-in-use": "Einen neuen Verifizierungscode generieren",
}
    
export default buttons;