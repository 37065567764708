const catering = {   
    "Catering-1": "Are you still looking for a sweet addition to your dessert offering? No problem! We come with our Ape to your wedding, your birthday party, or your team event.",
    "Catering-2": "In our luggage, six different ice cream varieties, a coffee machine for various coffee specialties, and delicious Amaretti from \"das Naschwerk\" from Allschwil.",
    "Catering-3": "Fill out the contact form below so we can send you a suitable offer for your event promptly.",
    "Catering-4": "Please note that we cannot travel long distances with our Ape, so we are mainly available in the Basel region.",
    "Catering-5": "Do you want us to be there with you? Then we look forward to your ",
    "Catering-6": "request!",
    "Catering-show-form": "Submit request!",
}

export default catering;