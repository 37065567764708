import * as Yup from 'yup';
import { useFirstTimeModalHomeData } from './useFirstTimeModalHomeData.js';

export const useFirstTimeModalHomeYupValidations = () => {

    const { firstTimeModalHomeIntlErrors } = useFirstTimeModalHomeData();

    const registerSchema = Yup.object().shape({
        username: Yup.string()
            .required(firstTimeModalHomeIntlErrors.name_required)
            .min(2, (firstTimeModalHomeIntlErrors.name_min)),
        email: Yup.string()
            .email(firstTimeModalHomeIntlErrors.email_invalid)
            .required(firstTimeModalHomeIntlErrors.email_required),
        language: Yup.string()
            .required(firstTimeModalHomeIntlErrors.language_required)
    });

    return { registerSchema };
}