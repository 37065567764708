const handleLocations = ( location, setZoomMap, setCurrentLocation,
                           currentLocation, averageLat, averageLng ) => {

    if (
        location.coordinates.lat === currentLocation.lat &&
        location.coordinates.lng === currentLocation.lng) {
            setZoomMap(14);
            setTimeout(() => {
                setZoomMap(17);
            }, 1000);
    } else {
        setCurrentLocation({ lat:averageLat, lng:averageLng });
        setZoomMap(14);
        setTimeout(() => {
            setCurrentLocation({ lat:location.coordinates.lat, lng:location.coordinates.lng });
            setZoomMap(17);
        }, 1000);
    }

}

export default handleLocations;