import React from 'react';
import { Modal } from 'react-bootstrap';
import './modal.css'
import { FormattedMessage } from 'react-intl';

const OnlineShopping = ({ showOnlineShopping, setShowOnlineShopping }) => {
    
    return (
        <Modal id='modal-online-shop' show={showOnlineShopping} onHide={() => setShowOnlineShopping(false)}>
        <div className='online-shop-background-modal'>
            <Modal.Header>
                <Modal.Title>
                <FormattedMessage id="onlineShopping-1" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='online-shop-modal-body'>
                <p>
                    <FormattedMessage id="onlineShopping-2" />
                </p>
            </Modal.Body>
        </div>
        </Modal>
    );
}

export default OnlineShopping;
