import React, { useEffect, useState } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import PATHS from '../../store/models/routes.js';
import OnlineShopping from './modals/OnlineShopping.jsx';
import { RxHamburgerMenu } from "react-icons/rx";
import { FormattedMessage } from 'react-intl';
import DropdownLenguagesMenu from './DropdownLenguagesMenu.jsx';

const HeaderNavBar = ({ currentPath }) => {

    const [showOnlineShopping, setShowOnlineShopping] = useState();
    const [showMenu, setShowMenu] = useState(false);
    const role = localStorage.getItem("role") || null;

    const handleMenuToggle = () => {
        setShowMenu(!showMenu);
    }
    
    useEffect(() => {

        const handleCloseNavbar = (event) => {
            if (showMenu) {
            const navbar = document.querySelector('.navbar-container');
            const navbarBrand = document.querySelector('.navbar-brand');
            const navbarLocations = document.getElementById('standorte');
            if (
                (navbar && !navbar.contains(event.target)) ||
                (navbarBrand && navbarBrand.contains(event.target)) ||
                (navbarLocations && navbarLocations.contains(event.target))
                ) {
                setShowMenu(false)
            }}
        }

        document.addEventListener('click', handleCloseNavbar);
    }, [showMenu]);

    const isInHome = currentPath === '/';

    const getNavbarClass = () => {
        const showMenuClass = showMenu ? "header-nav-bar header-nav-bar-toggled" : "header-nav-bar";
        const isInHomeClass = isInHome ? "" : "header-nav-bar-templates";
        return (showMenuClass + " " + isInHomeClass)
    }

    return (
        <div className={getNavbarClass()}>
            <Navbar expand="lg" className="bg-body-tertiary">
                <Container className='navbar-container'>
                    <DropdownLenguagesMenu />
                    <Navbar.Brand className='brand-name-font-style header-nav-bar-brand-name' href={`${PATHS.HOME}#home-section-1`}>Pandito</Navbar.Brand>
                    <button className="navbar-toggle navbar-toggler" onClick={() => handleMenuToggle()} style={{color: 'white'}}> 
                        <RxHamburgerMenu />
                    </button>
                    <Navbar.Collapse id="basic-navbar-nav" className={`custom-collapse ${showMenu ? 'show' : ''}`}>
                        <Nav className="ms-auto">
                            <Nav.Link href={PATHS.ABOUT_US}>
                                <FormattedMessage id="headerNavBar-navLink1" />
                            </Nav.Link>
                            <Nav.Link href={PATHS.OUR_PRODUCTS}>
                                <FormattedMessage id="headerNavBar-navLink2" />
                            </Nav.Link>
                            <Nav.Link href={PATHS.CATERING}>
                                <FormattedMessage id="headerNavBar-navLink3" />
                            </Nav.Link>
                            <Nav.Link id='standorte' href={`${PATHS.HOME}#home-section-5`}>
                                <FormattedMessage id="headerNavBar-navLink4" />
                            </Nav.Link>
                            <Nav.Link href="" onClick={() => setShowOnlineShopping(true)}>
                                <FormattedMessage id="headerNavBar-navLink5" />
                            </Nav.Link>
                            <Nav.Link href={PATHS.OUR_PARTNERS}>
                                <FormattedMessage id="headerNavBar-navLink6" />
                            </Nav.Link>
                            {role === "admin" && (
                                <Nav.Link href={PATHS.ADMIN.ROOT}>
                                    <FormattedMessage id="headerNavBar-navLink7" />
                                </Nav.Link>
                            )}     
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <OnlineShopping
                showOnlineShopping={showOnlineShopping}
                setShowOnlineShopping={setShowOnlineShopping}
            />
        </div>
    );
}

export default HeaderNavBar;
