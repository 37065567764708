const headerNavbar = {
  "headerNavBar-navLink1": "About us",
  "headerNavBar-navLink2": "Our offer",
  "headerNavBar-navLink3": "Catering",
  "headerNavBar-navLink4": "Locations",
  "headerNavBar-navLink5": "Online-shop",
  "headerNavBar-navLink6": "Partners",
  "headerNavBar-navLink7": "Admin",
};

export default headerNavbar;
