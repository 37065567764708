const loginForm = {   
    "login-form-email-label": "Dirección de correo electrónico",
    "login-form-email-placeholder": "Tu dirección de correo electrónico aquí...",
    "login-form-email-error-invalid": "Dirección de correo electrónico no válida",
    "login-form-email-error-required": "El correo electrónico es obligatorio",
    "login-form-password-label": "Contraseña",
    "login-form-password-placeholder": "Tu contraseña aquí...",
    "login-form-password-error-required": "La contraseña es obligatoria",
    "login-form-sending-form-message": "Iniciando sesión para el usuario, por favor espere",
    "login-form-success-message": "Inició de sesión exitoso",
}
    
export default loginForm;