const catering = {   
    "Catering-1": "Du suchst noch eine süsse Ergänzung zu deinem Dessertangebot? Kein Problem! Wir kommen mit unserer Ape auf deine Hochzeit, zu deiner Geburtstagsfeier oder zu deinem Teamevent.",
    "Catering-2": "Im Gepäck sechs verschiedene Eissorten, eine Kaffeemaschine für verschiedene Kaffeespezialitäten und feine Amaretti von „das Naschwerk“ aus Allschwil.",
    "Catering-3": "Fülle das untenstehende Kontaktformular aus, damit wir dir zeitnah eine passende Offerte für dein Fest schicken können.",
    "Catering-4": "Bitte beachte, dass wir mit unserer Ape nicht allzu lange Strecken zurücklegen können, weshalb wir vor allem in der Region Basel unterwegs sind",
    "Catering-5": "Möchtest Du uns dabeihaben?! Dann freuen wir uns auf deine ",
    "Catering-6": "anfrage!",
    "Catering-show-form": "Anfrage absenden!",
}

export default catering;