import { useIntl } from "react-intl";

export const useCateringFormData = () => {
    
    const intl = useIntl();

    // Form values depending lenguages
    const cateringIntlFormData = [
        { id: "name", type:"text", label: intl.formatMessage({ id: "catering-form-name-label" }), placeholder: intl.formatMessage({ id:"catering-form-name-placeholder" }) },
        { id: "surname", type:"text", label: intl.formatMessage({ id: "catering-form-surname-label" }), placeholder: intl.formatMessage({ id:"catering-form-surname-placeholder" }) },
        { id: "company", type:"text", label: intl.formatMessage({ id:"catering-form-company-label" }), placeholder: intl.formatMessage({ id:"catering-form-company-placeholder" }) },
        { id: "companyAddress", type:"text", label: intl.formatMessage({ id:"catering-form-company-address-label" }), placeholder: intl.formatMessage({ id:"catering-form-company-address-placeholder" }) },
        { id: "email", type:"email", label: intl.formatMessage({ id:"catering-form-email-label" }), placeholder: intl.formatMessage({ id:"catering-form-email-placeholder" }) },
        { id: "phoneNumber", type:"tel", label: intl.formatMessage({ id:"catering-form-phone-number-label" }), placeholder: intl.formatMessage({ id:"catering-form-phone-number-placeholder" }) },
        { id: "date", type:"date", label: intl.formatMessage({ id:"catering-form-date-label" }), placeholder: intl.formatMessage({ id:"catering-form-date-placeholder" }) },
        { id: "startTime", type:"time", label: intl.formatMessage({ id:"catering-form-time-start-label" }), placeholder: intl.formatMessage({ id:"catering-form-time-start-placeholder" }) },
        { id: "finishTime", type:"time", label: intl.formatMessage({ id:"catering-form-time-finish-label" }), placeholder: intl.formatMessage({ id:"catering-form-time-finish-placeholder" }) },
        { id: "typeOfEvent", type:"text", label: intl.formatMessage({ id:"catering-form-type-of-event-label" }), placeholder: intl.formatMessage({ id:"catering-form-type-of-event-placeholder" }) },
        { id: "location", type:"text", label: intl.formatMessage({ id:"catering-form-event-location-label" }), placeholder: intl.formatMessage({ id:"catering-form-event-location-placeholder" }) },
        { id: "numberOfPeople", type:"number", label: intl.formatMessage({ id:"catering-form-number-of-people-label" }), placeholder: intl.formatMessage({ id:"catering-form-number-of-people-placeholder" }) },
        { id: "message", type:"text", label: intl.formatMessage({ id:"catering-form-message-label" }), placeholder: intl.formatMessage({ id:"catering-form-message-placeholder" }) },
        ];

    const cateringIntlErrors = {
        name_required: intl.formatMessage({ id:"catering-form-name-error-required" }),
        surname_required: intl.formatMessage({ id:"catering-form-surname-error-required" }),
        email_required: intl.formatMessage({ id:"catering-form-email-error-required" }),
        email_invalid: intl.formatMessage({ id:"catering-form-email-error-invalid" }),
        phone_number_required: intl.formatMessage({ id:"catering-form-phone-number-error-required" }),
        phone_number_invalid: intl.formatMessage({ id:"catering-form-phone-number-error-invalid" }),
        date_required: intl.formatMessage({ id:"catering-form-date-error-required" }),
        date_invalid: intl.formatMessage({ id:"catering-form-date-error-invalid" }), 
        start_time_required: intl.formatMessage({ id:"catering-form-time-start-error-required" }),
        finish_time_required: intl.formatMessage({ id:"catering-form-time-finish-error-required" }),
        type_of_event_required: intl.formatMessage({ id:"catering-form-type-of-event-error-required" }),
        event_location_required: intl.formatMessage({ id:"catering-form-event-location-error-required" }),
        number_of_people_required: intl.formatMessage({ id:"catering-form-number-of-people-error-required" }),
        number_of_people_invalid: intl.formatMessage({ id:"catering-form-number-of-people-error-invalid" }),
        number_of_people_min: intl.formatMessage({ id:"catering-form-number-of-people-error-min" }),
        message_required: intl.formatMessage({ id:"catering-form-message-error-required" }),
    }

    const cateringIntlMessages = {
        submittingMessage: intl.formatMessage({ id:"catering-sending-form-message" }),
        successMessage: intl.formatMessage({ id:"catering-success-message" }),
    }
    
    const cateringIntlButtons = {
        buttonSend: intl.formatMessage({ id:"button-send" }),
    }

    const cateringInitialValues = {
        name: '',
        surname: '',
        company: '',
        companyAddress: '',
        email: '',
        phoneNumber: '',
        date: '',
        startTime: '',
        finishTime: '',
        typeOfEvent: '',
        location: '',
        numberOfPeople: '',
        message: '',
        }

    return { cateringIntlFormData, cateringIntlErrors, cateringIntlMessages, cateringIntlButtons, cateringInitialValues };
};