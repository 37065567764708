const partners = {   
    "partners-main-title": "Collaborating with Pandito",
    "partner-1-title": "Das Naschwerk",
    "partner-1-content": "Fine amaretti, available at our Ape from the small business Das Naschwerk in Allschwil, Baselland. Two varieties, gluten-free, and perfect with a good coffee.",
    "partner-1-link": "https://das-naschwerk.com/",
    "partner-2-title": "La Columbiana",
    "partner-2-content": "We source our coffee beans from the Basel coffee roastery La Columbiana. We use them to make various coffee specialties like iced coffee, cappuccino, or affogato with a scoop of vanilla ice cream.",
    "partner-2-link": "https://lacolumbiana.ch/",
    "partner-3-title": "Miba",
    "partner-3-content": "We source the products for our milk ice cream from Miba in Aesch, Baselland. This includes milk, whole cream, and yogurt, all essential for good milk ice cream.",
    "partner-3-link": "https://mibabasel.ch/",
    "partner-4-title": "Dry Raw Materials",
    "partner-4-content": "All our dry raw materials needed for our ice creams are sourced from Prodega in Pratteln, Baselland.",
    "partner-4-link": "https://www.transgourmet.ch/de/co",
    "partner-5-title": "Pacovis AG",
    "partner-5-content": "Non-food items, as well as our vegan ice cream cones and various nut pastes, come from Pacovis in Stetten, Aargau.",
    "partner-5-link": "https://www.pacovis.ch/stetten",
    "partner-6-title": "Wickelfisch",
    "partner-6-content": "The location of our Ape on the Rhine is on the way to the perfect Rhine access near the Tinguely Museum in Basel. The waterproof swim bag is a must-have that you can easily purchase from our Ape.",
    "partner-6-link": "https://www.wickelfisch.ch/",
    "partner-7-title": "Westfeld",
    "partner-7-content": "From the hospital grounds to the neighborhood center. Westfeld is a vibrant piece of the city where our gelateria has also found its place.",
    "partner-7-link": "https://www.westfeld-basel.ch/",
    "partner-8-title": "Tigel",
    "partner-8-content": "From Ticino, from Tigel, comes, among other things, the showcase in our gelateria or also our blast freezer in production.",
    "partner-8-link": "https://ti-gel.ch/",
    "partner-9-title": "Espura",
    "partner-9-content": "Beautiful craftsmanship from Argentina & Latin America is reflected in our gelateria, like the ceiling lamps. The beautiful boutique with many unique pieces is based in Flumenthal, Solothurn.",
    "partner-9-link": "https://espura.ch/",
}

export default partners;