const cateringForm = {   
    "catering-form-name-label": "Nombre",
    "catering-form-name-placeholder": "Tu nombre aquí...",
    "catering-form-name-error-required": "El nombre es obligatorio",
    "catering-form-surname-label": "Apellido",
    "catering-form-surname-placeholder": "Tu apellido aquí...",
    "catering-form-surname-error-required": "El apellido es obligatorio",
    "catering-form-company-label": "Empresa",
    "catering-form-company-placeholder": "Nombre de tu empresa aquí...",
    "catering-form-company-address-label": "Dirección de la Empresa",
    "catering-form-company-address-placeholder": "Dirección de tu empresa aquí...",
    "catering-form-email-label": "Correo Electrónico",
    "catering-form-email-placeholder": "Tu correo electrónico aquí...",
    "catering-form-email-error-invalid": "Correo electrónico inválido",
    "catering-form-email-error-required": "El correo electrónico es obligatorio",
    "catering-form-phone-number-label": "Número de Teléfono",
    "catering-form-phone-number-placeholder": "Tu número de teléfono aquí...",
    "catering-form-phone-number-error-invalid": "Solo se permiten números",
    "catering-form-phone-number-error-required": "El número de teléfono es obligatorio",
    "catering-form-date-label": "Fecha",
    "catering-form-date-placeholder": "Selecciona una fecha",
    "catering-form-date-error-invalid": "La fecha debe ser igual o posterior al día actual",
    "catering-form-date-error-required": "La fecha es obligatoria",
    "catering-form-time-start-label": "Hora comienzo (aprox)",
    "catering-form-time-start-placeholder": "Selecciona una hora",
    "catering-form-time-start-error-required": "La hora comienzo es obligatoria",
    "catering-form-time-finish-label": "Hora finalizacion (aprox)",
    "catering-form-time-finish-placeholder": "Selecciona una hora",
    "catering-form-time-finish-error-required": "La hora finalizacion es obligatoria",
    "catering-form-type-of-event-label": "Tipo de Evento",
    "catering-form-type-of-event-placeholder": "Describe el tipo de evento aquí...",
    "catering-form-type-of-event-error-required": "El tipo de evento es obligatorio",
    "catering-form-event-location-label": "Ubicación del Evento",
    "catering-form-event-location-placeholder": "Ubicación del evento aquí...",
    "catering-form-event-location-error-required": "La ubicación del evento es obligatoria",
    "catering-form-number-of-people-label": "Número de Personas",
    "catering-form-number-of-people-placeholder": "Número de personas aquí...",
    "catering-form-number-of-people-error-min": "El número de personas debe ser al menos 1",
    "catering-form-number-of-people-error-invalid": "Solo se permiten números",
    "catering-form-number-of-people-error-required": "El número de personas es obligatorio",
    "catering-form-message-label": "Mensaje",
    "catering-form-message-placeholder": "Escribe tu mensaje aquí...",
    "catering-form-message-error-required": "El mensaje es obligatorio",
    "catering-sending-form-message": "Enviando tu mensaje, por favor espera",
    "catering-success-message": "Su email ha sido enviado con exito. Nos pondremos en contacto con usted brevemente. Gracias por elegirnos",
}
    
export default cateringForm;