const partners = {   
"partners-main-title": "Colaborando con Pandito",
    "partner-1-title": "Das Naschwerk",
    "partner-1-content": "Del pequeño negocio Das Naschwerk en Allschwil, Baselland, puedes encontrar deliciosos amaretti en nuestra Ape. Dos variedades, sin gluten y perfectas para acompañar un buen café.",
    "partner-1-link": "https://das-naschwerk.com/",
    "partner-2-title": "La Columbiana",
    "partner-2-content": "Obtenemos nuestros granos de café de la tostadora de café de Basilea La Columbiana. Con ellos, preparamos varias especialidades de café como café helado, cappuccino o affogato con una bola de helado de vainilla.",
    "partner-2-link": "https://lacolumbiana.ch/",
    "partner-3-title": "Miba",
    "partner-3-content": "Obtenemos los productos para nuestro helado de leche de Miba en Aesch, Baselland. Esto incluye leche, nata entera y yogur, todo lo que se necesita para un buen helado de leche.",
    "partner-3-link": "https://mibabasel.ch/",
    "partner-4-title": "Materias Primas Secas",
    "partner-4-content": "Todas nuestras materias primas secas necesarias para nuestros helados las obtenemos de Prodega en Pratteln, Baselland.",
    "partner-4-link": "https://www.transgourmet.ch/de/co",
    "partner-5-title": "Pacovis AG",
    "partner-5-content": "Artículos no alimentarios, así como nuestros conos de helado veganos y las diversas pastas de nueces, las obtenemos de Pacovis en Stetten, Argovia.",
    "partner-5-link": "https://www.pacovis.ch/stetten",
    "partner-6-title": "Wickelfisch",
    "partner-6-content": "La ubicación de nuestra Ape en el Rin está en el camino hacia el acceso perfecto al Rin, cerca del Museo Tinguely en Basilea. La bolsa de natación impermeable es un imprescindible que puedes comprar fácilmente en nuestra Ape.",
    "partner-6-link": "https://www.wickelfisch.ch/",
    "partner-7-title": "Westfeld",
    "partner-7-content": "Del terreno del hospital al centro del barrio. Westfeld es un vibrante pedazo de la ciudad donde también ha encontrado su lugar nuestra heladería.",
    "partner-7-link": "https://www.westfeld-basel.ch/",
    "partner-8-title": "Tigel",
    "partner-8-content": "Desde el Tesino, de Tigel, proviene, entre otras cosas, la vitrina de nuestra heladería o también nuestro congelador rápido en la producción.",
    "partner-8-link": "https://ti-gel.ch/",
    "partner-9-title": "Espura",
    "partner-9-content": "La hermosa artesanía de Argentina y América Latina se refleja en nuestra heladería, como las lámparas de techo. La hermosa boutique con muchas piezas únicas tiene su sede en Flumenthal, Solothurn.",
    "partner-9-link": "https://espura.ch/",
}

export default partners;