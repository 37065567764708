import { ErrorMessage, Field } from "formik";
import Label from "../../../atoms/formParts/Label";

const FirstTimeModalHomeFormDataMap = ({ cateringIntlFormData, errors, touched }) => {
    return (
        <div className='form-columns'>
            {cateringIntlFormData.map((data, index) => (
                <div className='form-group' key={index}>
                    <Label>{data.label}</Label>
                    {data.type === 'select' ? (
                        <div>
                        <Field as="select" className='input' id={data.id} name={data.id}>
                            {data.options.map((option, optionIndex) => (
                                <option key={optionIndex} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </Field>
                        <p>{data.info}</p>
                        </div>
                    ) : (
                        <Field
                            className='input'
                            id={data.id}
                            name={data.id}
                            autoComplete={data.label}
                            placeholder={data.placeholder}
                            type={data.type}
                        />
                    )}
                    {errors[data.id] && touched[data.id] && (
                        <ErrorMessage className='message error' component="div" name={data.id} />
                    )}
                </div>
            ))}
        </div>
    );
}

export default FirstTimeModalHomeFormDataMap;