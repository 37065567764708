const aboutUs = {   
    "aboutUs-1": "PANDITO PANDITO PANDITO - The name PANDITO originates in Argentina.",
    "aboutUs-2": "That's where we, Sara and Lucia, are also originally from.",
    "aboutUs-3": "When we decided to buy a small food cart in 2022, we thought about what feeling we wanted to convey with our name.",
    "aboutUs-4": "When we were little and in Argentina, we were often told: 'Stay where PANDITO is'. Simply translated: Stay where you feel safe, secure, and familiar. That's exactly what we want!",
    "aboutUs-5": "We want to give you a PANDITO place with our gelateria.",
    "aboutUs-6": "With our Pandito offerings, we create a cozy place for you to unwind from your everyday life and enjoy, and be with a little bit of Latin America in ",
    "aboutUs-7": "Basel.",
    "aboutUs-Sara-1": "Gastronomy experience, many years in ice cream making, great interest, and the accumulated experiences contribute to Sara making ice cream gladly and late into the night, developing, testing, and trying out new, diverse ice cream creations.",
    "aboutUs-Sara-2": "After some time traveling and searching for a suitable place for entrepreneurship, Sara came to Switzerland in her early 20s and gained a lot of knowledge in gastronomy. Through Lucia, the dream of independence was realized, and many things were fulfilled for Sara.",
    "aboutUs-Sara-3": "Sara is made happy by a good coffee, an ice cream with chocolate chunks - but it shouldn't be too sweet - and the smile of customers when trying a Pandito ice cream.",
    "aboutUs-Luci-1": "Thanks to 6 years of gelateria experience, Lucia has gained knowledge and experience in various manufacturing methods and combining the most diverse flavors.",
    "aboutUs-Luci-2": "When she found the food cart online by chance, everything happened very quickly, and Sara was also convinced to take the leap into entrepreneurship.",
    "aboutUs-Luci-3": "Lucia is usually on the move as a Pilates trainer and otherwise - and she can't really get into sorbets – she finds them too boring. ;) A hot coffee with oat milk, a sweet, creamy ice cream with lots of crunch, and interacting with people at the cart make her very happy.",
}



export default aboutUs;