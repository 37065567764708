import { IntlProvider } from 'react-intl';
import messages_de from './de/main/de.js';
import messages_en from './en/main/en.js';
import messages_es from './es/main/es.js';

const messages = {
  de: messages_de,
  en: messages_en,
  es: messages_es
};

export function IntlManager({ children, locale }) {
  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      {children}
    </IntlProvider>
  );
}