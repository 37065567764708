import { Button } from "react-bootstrap";

export const renderLanguageButtons = (
        language,
        setLanguageSelected,
        languageSelected,
        setFieldValue,
        newsletterButtons
    ) => (
        
    <Button
        key={language}
        variant={languageSelected === language ? 'primary' : 'secondary'}
        className={languageSelected === language ? 'button-selected' : ''}
        onClick={() => {
            setFieldValue('to', language)
            setLanguageSelected(language)
        }}
    >
        {newsletterButtons[`newsletter_form_to_${language}`]}
    </Button>
)