const footerNavBar = {
  "footerNavBar-1": "© Pandito GmbH CH-270.4.008.394-2",
  "footerNavBar-2": "+41 77 409 1740 panditoicecream@gmail.com",
  "footerNavBar-3": "Schaffhauserreinweg Öffnungszeiten",
  "footerNavBar-3-tooltip": "Donnerstag bis Sonntag von 14:00 - 19:30 Uhr",
  "footerNavBar-4": "Im Westfeld Öffnungszeiten",
  "footerNavBar-4-tooltip": "Dienstag und Mittwoch von 14:00 - 19:30 Uhr",
  "footerNavBar-5": "Impressum / ",
  "footerNavBar-6": "Datenschutz",
  "footerNavBar-7": "Entworfen von Fede",
}

export default footerNavBar;