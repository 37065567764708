const newsletterErrorCodes = {
    "email-not-valid": "Dirección de correo electrónico no válida",
    "name-not-valid": "Nombre no válido",
    "email-already-in-use": "Dirección de correo electrónico ya en uso",
    "error-while-processing-newsletter-registration": "Error al procesar la inscripción al boletín informativo",
    "user-already-veryficated": "Usuario ya verificado",
    "email-not-registrated": "Email no registrado",
    "server-error-while-verifying-user-email": "Error del servidor al verificar el email del usuario",
    "server-error-while-verifying-user-data": "Error del servidor al verificar los datos del usuario",
    "invalid-credentials": "Credenciales inválidas",
    "language-not-valid": "Idioma no valido",
    "server-error-while-getting-newsletter-users": "error del servidor al obtener usuarios del boletín informativo",
    "subject-not-valid": "Asunto no valido",
    "message-not-valid": "Mensaje no valido",
    "some-email-delivers-failed": "Algunos envios de email han fallado",
    "server-error-while-sending-emails": "error del servidor al enviar emails",
    "no-verified-users-found-for-selected-language": "No se han encontrado usuarios verificados para el idioma seleccionado",
    "user-already-unsubscribed": "usuario ya dado de baja",
    "server-error-while-unsubscribing-user": "error del servidor al dar de baja al usuario",
    "invalid-credentials-please-try-again-later-or-contact-us": "credenciales inválidas. Por favor, inténtelo más tarde o contáctenos",
    "your-token-has-expired-we-have-sent-you-an-email-with-new-credentials": "su token ha expirado. Le hemos enviado un correo electrónico con nuevas credenciales",
    "invalid-token-we-have-sent-you-an-email-with-new-credentials": "token inválido. Le hemos enviado un correo electrónico con nuevas credenciales",
    "unknown-error-while-verifying-token-we-have-sent-you-an-email-with-new-credentials": "error desconocido al verificar el token. Le hemos enviado un correo electrónico con nuevas credenciales",
    "server-error-while-updating-newsletter-language": "error del servidor al actualizar el idioma del boletin informativo",
    "the-selected-language-is-the-same-as-the-current-language": "El idioma seleccionado es el mismo que el idioma actual",
};

export default newsletterErrorCodes;