const aboutUs = {   
    "aboutUs-1": "PANDITO PANDITO PANDITO - El nombre PANDITO tiene su origen en Argentina.",
    "aboutUs-2": "De ahí venimos nosotras dos, Sara y Lucía, también de nacimiento.",
    "aboutUs-3": "Cuando decidimos comprar un pequeño carrito de comida en 2022, pensamos en qué sentimiento queríamos transmitir con nuestro nombre.",
    "aboutUs-4": "Cuando éramos pequeñas y estábamos en Argentina, a menudo nos decían: “Quédate donde está PANDITO”. Simplemente traducido: Quédate donde te sientas seguro, protegido y familiarizado. ¡Eso es exactamente lo que queremos!",
    "aboutUs-5": "Queremos darte un lugar PANDITO con nuestra heladería.",
    "aboutUs-6": "Con nuestra oferta de Pandito, creamos para ti un lugar acogedor para desconectar de tu vida cotidiana y disfrutar, y estar con un poco de América Latina en ",
    "aboutUs-7": "Basilea.",
    "aboutUs-Sara-1": "Experiencia en gastronomía, muchos años en la fabricación de helados, gran interés, así como las experiencias acumuladas contribuyen a que Sara haga helados con gusto hasta altas horas de la noche y desarrolle, pruebe y experimente con nuevas y diversas creaciones de helados.",
    "aboutUs-Sara-2": "Después de algún tiempo viajando y buscando un lugar adecuado para emprender, Sara llegó a Suiza a principios de sus 20 años y adquirió muchos conocimientos en gastronomía. A través de Lucía, el sueño de la independencia se hizo realidad y muchas cosas se cumplieron para Sara.",
    "aboutUs-Sara-3": "A Sara le hace feliz un buen café, un helado con trozos de chocolate - pero no debe ser demasiado dulce - y la sonrisa de los clientes al probar un helado de Pandito.",
    "aboutUs-Luci-1": "Gracias a 6 años de experiencia en heladería, Lucía ha adquirido conocimientos y experiencia en varios métodos de fabricación y en la combinación de los sabores más diversos.",
    "aboutUs-Luci-2": "Cuando encontró por casualidad el carrito de comida online, todo sucedió muy rápido y Sara también se convenció de atreverse a emprender.",
    "aboutUs-Luci-3": "Lucía generalmente está en movimiento como entrenadora de pilates y de otras maneras, y no le interesan mucho los sorbetes - los encuentra demasiado aburridos. ;) Un café caliente con leche de avena, un helado dulce y cremoso con mucho crujido y la interacción con la gente en el carrito la hacen muy feliz.",
}
  
export default aboutUs;