import { useState } from "react";
import { Button } from "react-bootstrap";
import NewsletterUsersTable from "./NewsletterUsersTable";
import NewsletterButtons from "./NewsletterButtons";

const NewsletterUsers = ({ newsletterData, filterCurrentUsersByLanguage, currentNewsletterUsers, showAllNewsletterUsers }) => {

    const [showNewsletterUsers, setShowNewsletterUsers] = useState(false);
    
    return (
        <div>
            <div className="newsletter-buttons-show">
                <Button onClick={() => setShowNewsletterUsers(!showNewsletterUsers)} variant="primary">
                    {showNewsletterUsers ? 
                        newsletterData.newsletter_hide_users_component_button :
                        newsletterData.newsletter_show_users_component_button
                    }
                </Button>
            </div>
            {showNewsletterUsers && (
            <div className="newsletter-buttons-and-table-box">
                <NewsletterButtons
                    newsletterData={newsletterData}
                    filterCurrentUsersByLanguage={filterCurrentUsersByLanguage}
                    currentNewsletterUsers={currentNewsletterUsers}
                    showAllNewsletterUsers={showAllNewsletterUsers}
                />
                <NewsletterUsersTable 
                    currentNewsletterUsers={currentNewsletterUsers}
                    newsletterData={newsletterData}
                />
            </div>
            )}
        </div>
    );
};

export default NewsletterUsers;