const PATHS = {
    HOME: '/',
    ABOUT_US: '/about-us',
    OUR_PRODUCTS: '/our-products',
    CATERING: '/catering',
    OUR_PARTNERS: '/partners',
    UPDATE_LANGUAGE: '/update-language',
    ADMIN: {
      ROOT: '/admin',
      GEOLOCATION: '/admin/geolocation',
      GEOLOCATION_LOGIN: '/admin/login',
      GEOLOCATION_REGISTER: '/admin/register',
      NEWSLETTER: '/admin/newsletter',
    }
  };
  
export default PATHS;