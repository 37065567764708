import { Col, Row } from 'react-bootstrap';
import { backgroundImgs, productImgs } from '../../images/OurProducts/OurProducts.js';
import { FormattedMessage } from 'react-intl';

const OurProducts = () => {

    const [ icecreamBigBottomImg, ourIcecreamTopImg ] = backgroundImgs;
    const quantityIterations = Math.ceil(productImgs.length / 3);

    return (
        <div className='template'>
            <div className='top-template-image-container'>
            <img src={ourIcecreamTopImg} className='top-template-image' alt='TopTemplateImage'></img>
            <h1 className='custom-orange-h1'>
                <FormattedMessage id="sliderItems-3-title" />
            </h1>
            </div>
            <div className='template-box'>
                <p className='custom-black-p'>
                <span style={{fontWeight: 'bold'}}>
                    <FormattedMessage id="OurProducts-1" />
                </span>
                    <FormattedMessage id="OurProducts-2" />
                <br></br>
                    <FormattedMessage id="OurProducts-3" />
                <br></br>
                    <FormattedMessage id="OurProducts-4" />
                <br></br>
                    <FormattedMessage id="OurProducts-5" />
                <br></br>
                    <FormattedMessage id="OurProducts-6" />
                <br></br>
                <br></br>
                    <FormattedMessage id="OurProducts-7" />
                </p>
                {Array.from({ length: quantityIterations }, (_, groupIndex) => (
                    <Row key={groupIndex} className='description-box-row no-gap'>
                        {productImgs.slice(groupIndex * 3, groupIndex * 3 + 3).map((img, index) => (
                            <Col key={index} className='description-box-our-products' md={12} lg={4} xl={4}>
                                <a href={img.link} target='_blank' rel="noreferrer">
                                    <img src={img.img} className='sara-luci-photos' alt={img.title} />
                                </a>
                            </Col>
                        ))}
                    </Row>
                ))}
            </div>
            <div className='bottom-template-image-container'>
            <img src={icecreamBigBottomImg} className='bottom-template-image' alt='bottomTemplateImage'></img>
            </div>
            <div className='bottom-spacer'></div>
        </div>
    );
}

export default OurProducts;