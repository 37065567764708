import { Formik, Form } from 'formik';
import React, { useState } from 'react';
import BeigeButton from '../../../atoms/buttons/BeigeButton';
import { useNewsletterYupValidations } from './useNewsletterYupValidations';
import useNewsletterEmailSenderData from './useNewsletterEmailSenderData';
import NewsletterMapFormData from './NewsletterMapFormData';
import { Button } from 'react-bootstrap';
import { backendErrorMessageProcessor } from '../../../molecules/messageManager/backendErrorMessageProcessor';
import useBackendErrorsMessages from '../../../molecules/messageManager/useBackendErrorsMessages.js';
import { createMessageSlice } from '../../../../store/slices/createMessageSlice.js';
import postServiceForImg from '../../../../services/post_services/postServiceForImg.js';
import Loading from '../../../molecules/Loading.jsx';
import EmailFailed from './EmailFailed.jsx';

const NewsletterEmailSender = () => {

    const { newsletterData, newsletterButtons, newsletterFormMessages ,initialValues } = useNewsletterEmailSenderData();
    const { registerSchema } = useNewsletterYupValidations();
    const { backendErrors} = useBackendErrorsMessages();
    const { addMessage} = createMessageSlice();
    const [sendingEmail, setSendingEmail] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [emailFailedList, setEmailFailedList] = useState([]);
    const [showEmailFailedList, setShowEmailFailedList] = useState(false);
    
    const handleSubmit =  async (values) => {

        setSendingEmail(true)
        setShowEmailFailedList(false)
        setEmailFailedList([])

        const url = '/newsletter/send-newsletter-email';
        const response = await postServiceForImg(url, values);

        if (!response.success) {
            const results = response.results ? response.results : []

            const failedEmails = Array.isArray(results) &&
                results.length > 0 ?
                results.filter(email => email.status === "failed").map(item => item.email)
            : [];

            if (failedEmails.length > 0) {
                setShowEmailFailedList(true)
                setEmailFailedList(failedEmails)
            }

            const errorsTranslated = backendErrorMessageProcessor(backendErrors, response.errors);
            addMessage({ type: "error", content: errorsTranslated });
            setSendingEmail(false)

            return;
        }

        addMessage({ type: "success", content: newsletterFormMessages.newsletter_sending_email_all_success });
        setSendingEmail(false)
    }

    return (
        <div className='newsletter-email-sender'>

            <div onClick={() => setShowForm(!showForm)} className="newsletter-buttons-show">
                <Button variant='light'>
                    {showForm ? 
                        newsletterData.newsletter_hide_form_component :
                        newsletterData.newsletter_show_form_component
                    }
                </Button>
            </div>

            {showForm && (
                sendingEmail ? (
                <div className='sending-emails-box'>
                    <Loading message="newsletter-form-sending-form-message"/>
                </div>
                ) : (
            <Formik
                initialValues = {initialValues}
                validationSchema = {registerSchema}
                onSubmit= {handleSubmit}
            >
                            
                {({ errors, touched, setFieldValue }) => {
                    return (
                        <Form className='form'>
                            <NewsletterMapFormData 
                                errors={errors}
                                touched={touched} 
                                setFieldValue={setFieldValue}
                            />
                            <div className='form-button'>
                                <BeigeButton type="submit" disabled={sendingEmail}>
                                    {newsletterButtons.button_send}
                                </BeigeButton>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
            ))}

            {emailFailedList.length > 0 && (
                <div className='emails-failed-list-box'>
                    {showEmailFailedList ? (
                        <EmailFailed 
                            newsletterFormMessages={newsletterFormMessages}
                            setShowEmailFailedList={setShowEmailFailedList}
                            emailFailedList={emailFailedList}
                        />
                    ) : (
                        <div>
                            <Button variant='danger' onClick={() => setShowEmailFailedList(true)}>
                                {newsletterButtons.newsletter_form_show_failed_emails_button}
                            </Button>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default NewsletterEmailSender;
