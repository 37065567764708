const buttons = {   
    "button-send": "Enviar",
    "button-cancel": "Cancelar",
    "button-login": "Iniciar sesión",
    "button-register": "Registrarse",
    "button-subscribe": "Suscribirse",
    "button-close": "Cerrar",
    "button-verify": "Abrir correo electrónico",
    "button-email-in-use": "Generar un nuevo codigo de verificacion",
}
    
export default buttons;