import { apiInstance } from "../api_instance/apiInstance";

const postServiceForImg = async (url, values) => {
  try {

    const formData = new FormData();
    
    for (const [key, value] of Object.entries(values)) {
        formData.append(key, value);
    }
    
    const response = await apiInstance.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response.status >= 200 && response.status < 300) {
      return { success: true };
      } else {
      const results = response.results ? response.results : null;
      const errors = response?.data?.errors ? response.data.errors : [];
      return { success: false, errors: errors, results: results };
    }
  } catch (error) {
    const results = error?.response?.data?.results ? error.response.data.results : null;
    const errors = error?.response?.data?.errors ? error.response.data.errors : [];
    return { success: false, errors: errors, results: results };
  }
};

export default postServiceForImg;
