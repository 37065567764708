import RedButton from '../../../../atoms/buttons/RedButton';
import { useGeolocationData } from './useGeolocationData';

const GeolocationShearing = ({ handleClickShareLocation, gettingNewLocation }) => {

    const { geolocationButtons, geolocationMessages } = useGeolocationData();

    return (
        <>
            <p>
                {geolocationMessages.geolocation_sharing_location_message_1}
                <span style={{fontWeight: '900'}}>
                {geolocationMessages.geolocation_sharing_location_message_span}
                </span>
                {geolocationMessages.geolocation_sharing_location_message_2}
            </p>
            <p>
                {geolocationMessages.geolocation_sharing_location_message_button}
            </p>
            <div onClick={() => handleClickShareLocation(false)}>
                <RedButton disabled={gettingNewLocation} type='button'>
                {geolocationButtons.geolocation_button_stop_sharing_location}
                </RedButton>
            </div>
        </>
    );
}

export default GeolocationShearing;
