import { Button, Modal } from 'react-bootstrap';
import WhiteButton from '../../../../atoms/buttons/WhiteButton';
import { createMessageSlice } from '../../../../../store/slices/createMessageSlice';
import openEmailWindow from './openEmailWindow';
import { useFirstTimeModalSuccessData } from './useFirstTimeModalSuccessData';

const FirstTimeModalHomeNewVerificationCode = ({
        showFirstTimeModalNewVerificationCode,
        setShowFirstTimeModalNewVerificationCode,
        email
    }) => {

    const { addMessage } = createMessageSlice();
    const { messages } = useFirstTimeModalSuccessData();

    return (
        <Modal 
            className='modal-success'
            size='xl'
            show={showFirstTimeModalNewVerificationCode}
            onHide={() => setShowFirstTimeModalNewVerificationCode(false)}
        >
                <Modal.Header>
                    <Modal.Title >
                        {messages.title}
                    </Modal.Title>
                </Modal.Header>
                
                <Modal.Body className='modal-succes-body'>
                    {messages.success_new_code}
                    <div
                        className="white-button-box"
                        onClick={() => openEmailWindow(email, addMessage, messages)}>
                        <WhiteButton type="button" variant="secondary">
                            {messages.button_verify}
                        </WhiteButton>
                    </div>
                </Modal.Body>
        
                <Modal.Footer>
                    <Button onClick={() => setShowFirstTimeModalNewVerificationCode(false)} variant="secondary">
                        {messages.button_close}
                    </Button>
                </Modal.Footer>
        </Modal>
    );
}

export default FirstTimeModalHomeNewVerificationCode;
