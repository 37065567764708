import { Button } from 'react-bootstrap';

const NewsletterButtons = ({
        newsletterData,
        filterCurrentUsersByLanguage,
        currentNewsletterUsers,
        showAllNewsletterUsers,
    }) => {

    return (

        <div className="newsletter-buttons-box">
            <Button onClick={() => filterCurrentUsersByLanguage('english')} variant="primary">
                {newsletterData.newsletter_show_english_users_button}
            </Button>
            <Button onClick={() => filterCurrentUsersByLanguage('spanish')} variant="primary">
                {newsletterData.newsletter_show_spanish_users_button}
            </Button>
            <Button onClick={() => filterCurrentUsersByLanguage('german')} variant="primary">
                {newsletterData.newsletter_show_german_users_button}
            </Button>
            <Button onClick={showAllNewsletterUsers} variant="primary">
                {newsletterData.newsletter_show_all_users_button}
            </Button>
        </div>

    );
}

export default NewsletterButtons;
