import { useIntl } from "react-intl";

export const useFirstTimeModalHomeData = () => {
    
    const intl = useIntl();

    // Modal values depending lenguages
    const firstTimeModalHomeIntlFormData = [
        { id: "username", type:"text", label: intl.formatMessage({ id: "first-time-modal-home-form-name-label" }), placeholder: intl.formatMessage({ id:"first-time-modal-home-form-name-placeholder" }) },
        { id: "email", type:"email", label: intl.formatMessage({ id:"first-time-modal-home-form-email-label" }), placeholder: intl.formatMessage({ id:"first-time-modal-home-form-email-placeholder" }) },
        { id: "language", type:"select", label: intl.formatMessage({ id: "first-time-modal-home-form-language-label" }),
        info: intl.formatMessage({ id:"first-time-modal-home-form-language-info" }),
        options: [
            { label: intl.formatMessage({ id: "first-time-modal-home-form-language-select" }), value: '' },
            { label: intl.formatMessage({ id: "first-time-modal-home-form-language-english" }), value: 'english' },
            { label: intl.formatMessage({ id: "first-time-modal-home-form-language-spanish" }), value: 'spanish' },
            { label: intl.formatMessage({ id: "first-time-modal-home-form-language-german" }), value: 'german' }
        ]}    
    ];

    const firstTimeModalHomeIntlErrors = {
        name_required: intl.formatMessage({ id:"first-time-modal-home-form-name-error-required" }),
        name_min: intl.formatMessage({ id: "first-time-modal-home-form-name-error-min" }),
        email_required: intl.formatMessage({ id:"first-time-modal-home-form-email-error-required" }),
        email_invalid: intl.formatMessage({ id:"first-time-modal-home-form-email-error-invalid" }),
        language_required: intl.formatMessage({ id: "first-time-modal-home-form-language-required" }),
    }

    const firstTimeModalHomeIntlMessages = {
        submittingMessage: intl.formatMessage({ id:"first-time-modal-home-sending-form-message" }),
        submittingMessageNewVerificationCode: intl.formatMessage({ id:"first-time-modal-home-sending-form-message-new-verification-code" }), 
    }

    const firstTimeModalHomeIntlButtons = {
        buttonSuscribe: intl.formatMessage({ id:"button-subscribe" }),
        buttonEmailInUse: intl.formatMessage({ id:"button-email-in-use" }),
    }

    const firstTimeModalHomeIntlModalInfo = {
        modal_title: intl.formatMessage({ id:"first-time-modal-home-title" }),
        modal_body: intl.formatMessage({ id:"first-time-modal-home-body" }),
        modal_info: intl.formatMessage({ id:"first-time-modal-home-info" }),
    }

    const firstTimeModalHomeInitialValues = {
        username: '',
        email: '',
        language: '',
    }
    
    return { 
        firstTimeModalHomeIntlFormData,
        firstTimeModalHomeIntlErrors,
        firstTimeModalHomeIntlMessages,
        firstTimeModalHomeIntlButtons,
        firstTimeModalHomeIntlModalInfo,
        firstTimeModalHomeInitialValues,
    };
};