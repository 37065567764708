import { useState } from "react";
import getCurrentLocation from "./getCurrentLocation.js";
import { createMessageSlice } from "../../../../../store/slices/createMessageSlice.js";
import PATHS from "../../../../../store/models/routes.js";
import { backendErrorMessageProcessor } from "../../../../molecules/messageManager/backendErrorMessageProcessor.js";
import useBackendErrorsMessages from "../../../../molecules/messageManager/useBackendErrorsMessages.js";
import { useGeolocationData } from "./useGeolocationData.js";
import handleUpdateLocation from "./handleUpdateLocation.js";
import GeolocationNotShearing from "./GeolocationNotShearing.js";
import GeolocationShearing from "./GeolocationShearing.js";
import { createPanditoLocationSlice } from "../../../../../store/slices/createPanditoLocationSlice.js";

const Geolocation = () => {

  const [gettingNewLocation, setGettingNewLocation] = useState(false);
  const { addMessage } = createMessageSlice();
  const { backendErrors } = useBackendErrorsMessages();
  const { geolocationErrors, geolocationSuccess, geolocationButtons } = useGeolocationData();
  const { panditoLocationNow, setPanditoLocationNow } = createPanditoLocationSlice();

  // try to get location from client and set it in backend
  const handleClickShareLocation = async (share) => {

    setGettingNewLocation(true)
    let location = { "lat": null, "lng": null }

    if (share) {
      const getCurrentLocationResponse = await getCurrentLocation();
      if (!getCurrentLocationResponse.success) {
        const error = getCurrentLocationResponse.error? getCurrentLocationResponse.error : "geolocation_default_error"
        addMessage({ type: "error", content: geolocationErrors[error] });
        return
      }
      location = getCurrentLocationResponse.location
    }

    await handleUpdateLocation(
      setPanditoLocationNow,
      addMessage,
      backendErrorMessageProcessor,
      backendErrors,
      geolocationSuccess,
      location,
    )

    setGettingNewLocation(false)
  };

  return (
    <div className="geolocation">
            <div className="geolocation-buttons-box">
              {panditoLocationNow.lat === null && panditoLocationNow.lng === null ? (
                <GeolocationNotShearing 
                  handleClickShareLocation={handleClickShareLocation}
                  gettingNewLocation={gettingNewLocation}
                />
              ) : (
                <GeolocationShearing
                  handleClickShareLocation={handleClickShareLocation}
                  gettingNewLocation={gettingNewLocation}
                />
              )}
              <a href={`${PATHS.HOME}#home-section-5`}>
                {geolocationButtons.geolocation_button_see_map}
              </a>
            </div>
    </div>
  );  
};

export default Geolocation;


