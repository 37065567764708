import { FaSpinner } from 'react-icons/fa6';
import { FormattedMessage } from "react-intl";


const Loading = ({ message }) => {
    return (
        <div className="loading-box">
            <FormattedMessage id={message}/>
          <div className="loading-icon">
            <FaSpinner />
          </div>
        </div>
    );
}

export default Loading;
