function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const backendErrorMessageProcessor = (backendErrors, err) => {
    const errors = err ? err : [];
    const genericError = backendErrors.generic_error_message;

    try {
        if (errors.length === 0) {
            return genericError;
        }

        const translatedMessages = errors.map((message) => {
            let messagesFound = [];

            const formattedMessage = message.msg.toLowerCase();

            const errorMatch = Object.keys(backendErrors).find((key) => {
                return formattedMessage === key;
            })

            if (!errorMatch) {
                return genericError;
            }

            const newErrorFound = capitalizeFirstLetter(
                backendErrors[errorMatch].replace(/-/g, " ")
                );
            messagesFound = [...messagesFound, newErrorFound];

            return messagesFound;

        });

        const resultMessage = translatedMessages.join(', ')

        return resultMessage;

    } catch (error) {
        return genericError;
    }
};