const newsletterForm = {
    "newsletter-form-to-label": "An",
    "newsletter-form-to-spanish": "Benutzer auf Spanisch",
    "newsletter-form-to-english": "Benutzer auf Englisch",
    "newsletter-form-to-german": "Benutzer auf Deutsch",
    "newsletter-form-to-test": "Testbenutzer",
    "newsletter-form-to-error-required": "Wählen Sie die Benutzer aus, an die Sie die E-Mail senden möchten",
    "newsletter-form-to-error-invalid": "Ausgewählte Benutzer sind ungültig",
    "newsletter-form-subject-label": "Betreff",
    "newsletter-form-subject-placeholder": "Schreiben Sie den Betreff hier...",
    "newsletter-form-subject-error-required": "Schreiben Sie einen Betreff",
    "newsletter-form-message-label": "Nachricht",
    "newsletter-form-message-error-required": "Sie müssen ein Bild hochladen",
    "newsletter-form-sending-form-message": "E-Mails werden gesendet",
    "newsletter-form-img-loader-message": "Ziehen Sie das Bild hierher oder klicken Sie, um es auszuwählen",
    "newsletter-form-sending-email-all-success": "Alle E-Mails wurden erfolgreich gesendet",
    "newsletter-form-some-email-deliver-failed": "Einige E-Mails konnten nicht zugestellt werden",
    "newsletter-form-show-failed-emails-button": "Fehlgeschlagene E-Mails anzeigen",
}

export default newsletterForm;