const loginForm = {   
    "login-form-email-label": "Email Address",
    "login-form-email-placeholder": "Your email address here...",
    "login-form-email-error-invalid": "Invalid email address",
    "login-form-email-error-required": "Email is required",
    "login-form-password-label": "Password",
    "login-form-password-placeholder": "Your password here...",
    "login-form-password-error-required": "Password is required",
    "login-form-sending-form-message": "Currently logging in the user, please wait",
    "login-form-success-message": "User logged successfully",
}

export default loginForm;