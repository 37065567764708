const newsletterErrorCodes = {
    "email-not-valid": "Invalid email address",
    "name-not-valid": "Invalid name",
    "email-already-in-use": "Email address already in use",
    "error-while-processing-newsletter-registration": "error while processing newsletter registration",
    "user-already-veryficated": "User already veryficated",
    "email-not-registrated": "Email not registrated",
    "server-error-while-verifying-user-email": "Server error while verifying user email",
    "server-error-while-verifying-user-data": "Server error while verifying user data",
    "invalid-credentials": "Invalid credentials",
    "language-not-valid": "Invalid language",
    "server-error-while-getting-newsletter-users": "server error while getting newsletter users",
    "subject-not-valid": "Subject not valid",
    "message-not-valid": "Message not valid",
    "some-email-delivers-failed": "Some email deliveries failed",
    "server-error-while-sending-emails": "Server error while sending emails",
    "no-verified-users-found-for-selected-language": "No verified users found for selected language",
    "user-already-unsubscribed": "user already unsubscribed",
    "server-error-while-unsubscribing-user": "server error while unsubscribing user",
    "invalid-credentials-please-try-again-later-or-contact-us": "invalid credentials. Please try again later or contact us",
    "your-token-has-expired-we-have-sent-you-an-email-with-new-credentials": "your token has expired. We have sent you an email with new credentials",
    "invalid-token-we-have-sent-you-an-email-with-new-credentials": "invalid token. We have sent you an email with new credentials",
    "unknown-error-while-verifying-token-we-have-sent-you-an-email-with-new-credentials": "unknown error while verifying token. We have sent you an email with new credentials",  
    "server-error-while-updating-newsletter-language": "Server error while updating the newsletter language",
    "the-selected-language-is-the-same-as-the-current-language": "the-selected-language-is-the-same-as-the-current-language",
};

export default newsletterErrorCodes;