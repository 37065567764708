const aboutUs = {   
    "aboutUs-1": "PANDITO PANDITO PANDITO - Der Name PANDITO findet seinen Ursprung in Argentinien.",
    "aboutUs-2": "Von da kommen wir zwei, Sara und Lucia, auch gebürtig her.",
    "aboutUs-3": "Als wir uns im Jahr 2022 entschieden haben, eine kleine Gastroape zu kaufen, haben wir uns überlegt, welches Gefühl wir mit unserem Namen transportieren möchten.",
    "aboutUs-4": "Als wir klein waren und in Argentinien unterwegs, wurde uns oft gesagt: „Bleib da, wo PANDITO ist“. Einfach übersetzt: Bleib da, wo du dich sicher fühlst, geborgen und es dir vertraut ist. Genau das möchten wir!.",
    "aboutUs-5": "Wir wollen dir mit unserer Gelateria einen PANDITO-Ort geben.",
    "aboutUs-6": "Mit unserem Angebot von Pandito schaffen wir dir einen Wohlfühlort, zum Herunterfahren von deinem Alltag und zum Geniessen und Sein mit ein wenig Lateinamerika in ",
    "aboutUs-7": "Basel.",
    "aboutUs-Sara-1": "Gastronomieerfahrung, viele Jahre in der Eisherstellung, grosses Interesse sowie die gesammelten Erfahrungen tragen dazu bei, dass Sara gerne und bis tief in die Nacht Eis herstellt und neue, vielfältige Eiskreationen entwickelt, testet und probiert.",
    "aboutUs-Sara-2": "Nach einiger Zeit auf Reisen und der Suche nach einem passenden Ort für die Selbstständigkeit ist Sara mit Anfang 20 in die Schweiz gekommen und hat sich in der Gastronomie viel Wissen angeeignet. Durch Lucia wurde der Traum der Selbständigkeit umgesetzt und für Sara ging dadurch vieles in Erfüllung.",
    "aboutUs-Sara-3": "Glücklich macht Sara, ein guter Kaffee, ein Eis mit Schokoladenstückchen - aber es darf nicht zu süss sein - sowie das Strahlen der Kundschaft beim Probieren von einem Pandito-Eis.",
    "aboutUs-Luci-1": "Dank 6 Jahren Gelateria-Erfahrung hat sich Lucia Wissen und Erfahrung angeeignet für verschiedene Herstellungsmethoden sowie das Kombinieren von den unterschiedlichsten Geschmäckern.",
    "aboutUs-Luci-2": "Als sie die Gastroape per Zufall online gefunden hat, ging plötzlich alles ganz schnell und auch Sara war überzeugt die Selbständigkeit zu wagen.",
    "aboutUs-Luci-3": "Meistens ist Lucia in Bewegung als Pilatestrainerin und auch sonst - und kann mit Sorbets nicht viel anfangen – die sind ihr zu langweilig. ;) Einen heissen Kaffee mit Hafermilch, ein süsses, cremiges Eis mit viel Crunch und der Austausch mit den Menschen an der Ape machen sie dafür umso glücklicher.",
}
  
export default aboutUs;