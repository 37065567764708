import React from 'react';
import PropTypes from 'prop-types';

const RedButton = (props) => {
    return (
        <div>
            <button disabled={props.disabled} className='button button-red' type={props.type}>{props.children}</button>
        </div>
    );
}

RedButton.propTypes = {
    type: PropTypes.node.isRequired,
}

export default RedButton;
