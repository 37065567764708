const footerNavBar = {
  "footerNavBar-1": "© Pandito GmbH CH-270.4.008.394-2",
  "footerNavBar-2": "+41 77 409 1740 panditoicecream@gmail.com",
  "footerNavBar-3": "Schaffhauserreinweg Opening Hours",
  "footerNavBar-3-tooltip": "Thursday to Sunday from 14:00 to 19:30",
  "footerNavBar-4": "Im Westfeld Opening Hours",
  "footerNavBar-4-tooltip": "Tuesday and Wednesday from 14:00 to 19:30",
  "footerNavBar-5": "Legal Notice / ",
  "footerNavBar-6": "Privacy Policy",
  "footerNavBar-7": "Designed by Fede",
}

export default footerNavBar;