import SliderParent from '../../organisms/SliderParent';
import FirstTimeModalHome from '../../molecules/modals/first_time_modal_home/FirstTimeModalHome';
import { useEffect, useState } from 'react';
import FirstTimeModalSuccess from '../../molecules/modals/first_time_modal_home/first_time_modal_success/FirstTimeModalSuccess';
import FirstTimeModalHomeNewVerificationCode from '../../molecules/modals/first_time_modal_home/first_time_modal_success/FirstTimeModalHomeNewVerificationCode';
import { createMessageSlice } from '../../../store/slices/createMessageSlice';
import { backendErrorMessageProcessor } from '../../molecules/messageManager/backendErrorMessageProcessor';
import useBackendErrorsMessages from '../../molecules/messageManager/useBackendErrorsMessages';
import useHomeData from './useHomeData';

const Home = () => {

    const [showFirstTimeModalHome, setShowFirstTimeModalHome] = useState(true);
    const [showFirstTimeModalSuccess, setShowFirstTimeModalSuccess] = useState(false);
    const [showFirstTimeModalNewVerificationCode, setShowFirstTimeModalNewVerificationCode] = useState(false);
    const [userEamil, setUserEamil] = useState(null);
    const { addMessage } = createMessageSlice();
    const { backendErrors } = useBackendErrorsMessages();
    const { successMessages } = useHomeData();

    useEffect(() => {

        const queryParams = new URLSearchParams(window.location.search);
        const verificationSuccessParam = queryParams.get('emailValidationSuccess');
        const unsubscribeUserSuccess = queryParams.get('unsubscribeUserSuccess');

        const verificationErrorParam = queryParams.get('Errors')
        const paramsMmessages = [
            verificationSuccessParam,
            unsubscribeUserSuccess,
            verificationErrorParam
        ]

        const messageFounded = paramsMmessages.some(msg => msg !== null)

        if (messageFounded) {
            setShowFirstTimeModalHome(false)
            if (verificationSuccessParam) {
                addMessage({ type:"success", content: successMessages.newsletter_verification_successfully })
            }
            if (unsubscribeUserSuccess) {
                addMessage({ type:"success", content: successMessages.newsletter_unsubscribed_successfully })
            }
            if (verificationErrorParam) {
                const error = [{ msg: verificationErrorParam }]
                const errorsTranslated = backendErrorMessageProcessor(backendErrors, error);
                addMessage({ type: "error", content: errorsTranslated })
            }
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <SliderParent />
            <FirstTimeModalHome
                showFirstTimeModalHome={showFirstTimeModalHome}
                setShowFirstTimeModalHome={setShowFirstTimeModalHome}
                setShowFirstTimeModalSuccess={setShowFirstTimeModalSuccess}
                setShowFirstTimeModalNewVerificationCode={setShowFirstTimeModalNewVerificationCode}
                setUserEamil={setUserEamil}
            />
            <FirstTimeModalSuccess
                showFirstTimeModalSuccess={showFirstTimeModalSuccess}
                setShowFirstTimeModalSuccess={setShowFirstTimeModalSuccess}
                email={userEamil}
            />
            <FirstTimeModalHomeNewVerificationCode
                showFirstTimeModalNewVerificationCode={showFirstTimeModalNewVerificationCode}
                setShowFirstTimeModalNewVerificationCode={setShowFirstTimeModalNewVerificationCode}
                email={userEamil}
            />
        </div>
    );
}


export default Home;

